import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import {NgxTypedJsModule} from 'ngx-typed-js';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PopupModule } from '@ciri/ngx-popup'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { StoryComponent } from './components/layouts/story/story.component';
import { TeamComponent } from './components/layouts/team/team.component';
import { TestimonialComponent } from './components/layouts/testimonial/testimonial.component';
import { CtaComponent } from './components/layouts/cta/cta.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { CallToActionComponent } from './components/layouts/call-to-action/call-to-action.component';
import { SubscribeComponent } from './components/layouts/subscribe/subscribe.component';
import { ContactComponent } from './components/layouts/contact/contact.component';
import { PartnerComponent } from './components/layouts/partner/partner.component';
import { AboutComponent } from './components/layouts/about/about.component';
import { ServicesComponent } from './components/layouts/services/services.component';
import { PortfolioComponent } from './components/layouts/portfolio/portfolio.component';
import { SkillsComponent } from './components/layouts/skills/skills.component';
import { FeaturesComponent } from './components/layouts/features/features.component';
import { WhyWeDifferentComponent } from './components/layouts/why-we-different/why-we-different.component';
import { FunfactsComponent } from './components/layouts/funfacts/funfacts.component';
import { PricingComponent } from './components/layouts/pricing/pricing.component';
import { FaqComponent } from './components/layouts/faq/faq.component';
import { BlogComponent } from './components/layouts/blog/blog.component';
import { CtaTwoComponent } from './components/layouts/cta-two/cta-two.component';
import { WhoWeAreComponent } from './components/layouts/who-we-are/who-we-are.component';
import { FunfactsTwoComponent } from './components/layouts/funfacts-two/funfacts-two.component';
import { BlogOneComponent } from './components/pages/blog-one/blog-one.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { OurServicesComponent } from './components/pages/our-services/our-services.component';
import { SuccessRateComponent } from './components/layouts/success-rate/success-rate.component';
import { NavbarTwoComponent } from './components/layouts/navbar-two/navbar-two.component';
import { SpecialistHomeComponent } from './components/pages/specialist-home/specialist-home.component';
import { SpecialistFeaturesComponent } from './components/layouts/specialist/specialist-features/specialist-features.component';
import { SpecialistAboutComponent } from './components/layouts/specialist/specialist-about/specialist-about.component';
import { SpecialistServicesComponent } from './components/layouts/specialist/specialist-services/specialist-services.component';
import { SpecialistCtaComponent } from './components/layouts/specialist/specialist-cta/specialist-cta.component';
import { SpecialistFaqComponent } from './components/layouts/specialist/specialist-faq/specialist-faq.component';
import { SpecialistWhoWeAreComponent } from './components/layouts/specialist/specialist-who-we-are/specialist-who-we-are.component';
import { SpecialistFunfactsTwoComponent } from './components/layouts/specialist/specialist-funfacts-two/specialist-funfacts-two.component';
import { SpecialistTeamComponent } from './components/layouts/specialist/specialist-team/specialist-team.component';
import { SpecialistStoryComponent } from './components/layouts/specialist/specialist-story/specialist-story.component';
import { SpecialistBlogComponent } from './components/layouts/specialist/specialist-blog/specialist-blog.component';
import { SpecialistContactComponent } from './components/layouts/specialist/specialist-contact/specialist-contact.component';
import { SpecialistNavbarComponent } from './components/layouts/specialist/specialist-navbar/specialist-navbar.component';
import { SpecialistAboutUsComponent } from './components/layouts/specialist/specialist-about-us/specialist-about-us.component';
import { SpecialistAllServicesComponent } from './components/layouts/specialist/specialist-all-services/specialist-all-services.component';
import { LandingComponent } from './components/pages/landing/landing.component';
import { BlodbankFeaturesComponent } from './components/layouts/blood-bank/blodbank-features/blodbank-features.component';
import { BlodbankAboutComponent } from './components/layouts/blood-bank/blodbank-about/blodbank-about.component';
import { FamilyAboutComponent } from './components/layouts/family/family-about/family-about.component';
import { FamilyFeaturesComponent } from './components/layouts/family/family-features/family-features.component';
import { FamilyNavbarComponent } from './components/layouts/family/family-navbar/family-navbar.component';
import { FamilyFaqComponent } from './components/layouts/family/family-faq/family-faq.component';
import { FamilyCtaComponent } from './components/layouts/family/family-cta/family-cta.component';
import { FamilyServicesComponent } from './components/layouts/family/family-services/family-services.component';
import { FamilyFunfactsTwoComponent } from './components/layouts/family/family-funfacts-two/family-funfacts-two.component';
import { FamilyTeamComponent } from './components/layouts/family/family-team/family-team.component';
import { FamilyWhoWeAreComponent } from './components/layouts/family/family-who-we-are/family-who-we-are.component';
import { FamilyStoryComponent } from './components/layouts/family/family-story/family-story.component';
import { FamilyContactComponent } from './components/layouts/family/family-contact/family-contact.component';
import { FamilyHomeComponent } from './components/pages/family-home/family-home.component';
import { BloodbankHomeComponent } from './components/pages/bloodbank-home/bloodbank-home.component';
import { BloodbankNavbarComponent } from './components/layouts/blood-bank/bloodbank-navbar/bloodbank-navbar.component';
import { FamilyAboutUsComponent } from './components/layouts/family/family-about-us/family-about-us.component';
import { FamilyFacilitiesComponent } from './components/layouts/family/family-facilities/family-facilities.component';
import { BloodbankContactComponent } from './components/layouts/blood-bank/bloodbank-contact/bloodbank-contact.component';
import { SpecialistPortfolioComponent } from './components/layouts/specialist/specialist-portfolio/specialist-portfolio.component';
import { FamilyPortfolioComponent } from './components/layouts/family/family-portfolio/family-portfolio.component';
import { FamilyBlogComponent } from './components/layouts/family/family-blog/family-blog.component';
import { FamilySubscribeComponent } from './components/layouts/family/family-subscribe/family-subscribe.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpecialistBlogDetailsComponent } from './components/pages/specialist-blog-details/specialist-blog-details.component';
import { SpecialistSubscribeComponent } from './components/layouts/specialist/specialist-subscribe/specialist-subscribe.component';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';


@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    NavbarComponent,
    StoryComponent,
    TeamComponent,
    TestimonialComponent,
    CtaComponent,
    HomeTwoComponent,
    CallToActionComponent,
    SubscribeComponent,
    ContactComponent,
    PartnerComponent,
    AboutComponent,
    ServicesComponent,
    PortfolioComponent,
    SkillsComponent,
    FeaturesComponent,
    WhyWeDifferentComponent,
    FunfactsComponent,
    PricingComponent,
    FaqComponent,
    BlogComponent,
    CtaTwoComponent,
    WhoWeAreComponent,
    FunfactsTwoComponent,
    BlogOneComponent,
    BlogTwoComponent,
    BlogDetailsComponent,
    ErrorComponent,
    OurServicesComponent,
    SuccessRateComponent,
    NavbarTwoComponent,
    SpecialistHomeComponent,
    SpecialistFeaturesComponent,
    SpecialistAboutComponent,
    SpecialistServicesComponent,
    SpecialistCtaComponent,
    SpecialistFaqComponent,
    SpecialistWhoWeAreComponent,
    SpecialistFunfactsTwoComponent,
    SpecialistTeamComponent,
    SpecialistStoryComponent,
    SpecialistBlogComponent,
    SpecialistContactComponent,
    SpecialistNavbarComponent,
    SpecialistAboutUsComponent,
    SpecialistAllServicesComponent,
    LandingComponent,
    BloodbankHomeComponent,
    BlodbankFeaturesComponent,
    BlodbankAboutComponent,
    FamilyAboutComponent,
    FamilyFeaturesComponent,
    FamilyNavbarComponent,
    FamilyFaqComponent,
    FamilyCtaComponent,
    FamilyServicesComponent,
    FamilyFunfactsTwoComponent,
    FamilyTeamComponent,
    FamilyWhoWeAreComponent,
    FamilyStoryComponent,
    FamilyContactComponent,
    FamilyHomeComponent,
    BloodbankNavbarComponent,
    FamilyAboutUsComponent,
    FamilyFacilitiesComponent,
    BloodbankContactComponent,
    SpecialistPortfolioComponent,
    FamilyPortfolioComponent,
    FamilyBlogComponent,
    FamilySubscribeComponent,
    SpecialistBlogDetailsComponent,
    SpecialistSubscribeComponent,
    PrivacyComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxTypedJsModule,
    PopupModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule
  ],
  providers: [ScreenTrackingService, UserTrackingService, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
