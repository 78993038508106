<section id="portfolio" class="portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Meet our expert</span>
            <h2>Medical <b>Team</b></h2>
            <p>Our Medical Team, The Brain Behind Our Operations</p>
        </div>
        <!-- <div class="shorting-menu">
            <button class="filter" data-filter="all">All</button>
            <button class="filter" data-filter=".design">Design</button>
            <button class="filter" data-filter=".seo">SEO</button>
            <button class="filter" data-filter=".branding">Branding</button>
            <button class="filter" data-filter=".print">Print</button>
            <button class="filter" data-filter=".video">Video</button>
        </div> -->
        <div class="shorting">
            <div class="row">
                <div class="col-lg-4 col-md-6 mix design">
                    <div class="single-work">
                        <a href="../../../../assets/img/team/DrBabatundeMD.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../assets/img/team/DrBabatundeMD.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Dr. Babatunde Wale Okewale</h3>
                                <span>CEO, St Ives Organization</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix print seo">
                    <div class="single-work">
                        <a href="../../../../assets/img/team/DrAlozie.png" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../assets/img/team/DrAlozie.png" alt="work-image">
                            <div class="work-overlay">
                                <h3>Dr. Alozie Ndubuka</h3>
                                <span>Medical Director, St Ives Family Clinic, Ikeja</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix video branding">
                    <div class="single-work">
                        <a href="../../../../assets/img/team/DrAnigbodu.png" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../assets/img/team/DrAnigbodu.png" alt="work-image">
                            <div class="work-overlay">
                                <h3>Dr. Eunice Anigbodu</h3>
                                <span>Famil Health Specialist, St Ives Healthcare Organization</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix design print">
                    <div class="single-work">
                        <a href="../../../../assets/img/team/DrKinsley.png" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../assets/img/team/DrKinsley.png" alt="work-image">
                            <div class="work-overlay">
                                <h3>Dr. Kingsley Obodo</h3>
                                <span>Medical Director, St Ives Specialist, Ikeja</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix branding video">
                    <div class="single-work">
                        <a href="../../../../assets/img/team/team05.png" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../assets/img/team/team05.png" alt="work-image">
                            <div class="work-overlay">
                                <h3>Pencil Art</h3>
                                <span>Business</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix seo design">
                    <div class="single-work">
                        <a href="../../../../assets/img/team/team02.png" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../assets/img/team/team02.png" alt="work-image">
                            <div class="work-overlay">
                                <h3>Front View Psd</h3>
                                <span>Design</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix seo design">
                    <div class="single-work">
                        <a href="../../../../assets/img/team/team08.jpeg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../assets/img/team/team08.jpeg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Front View Psd</h3>
                                <span>Design</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>