<!-- <app-specialist-navbar></app-specialist-navbar> -->

<section class="page-title-area item-bg1">
    <div class="container">
        <a style="float: left;" class="btn" routerLink="/specialist-home"> <span><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.354 10.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"/>
          </svg></span> Go Back </a>
        <h2 class="white">Specialist Services</h2>
    </div>
</section>

<section class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>our services</span>
            <h2> St Ives <b>Specialist Services</b></h2>
            <!-- <p>Our Annual Statistics</p> -->
        </div>

        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne"
                                aria-expanded="false" aria-controls="collapseOne">
                                Medical Diagnstics<i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img class="mb-3" src="../../../../../assets/img/specialist/sp-diagnostics.jpg" alt="MedicalDiagnostics" />
                                The accurate identification of ailments and impairments is fundamental to effective
                                healthcare solutions delivery; therefore, we offer a full complementary set of
                                distinctive medical diagnostic services i.e. the processes and techniques of determining
                                which disease or condition explains a person’s symptoms and signs.
                                <p>
                                    We deploy cutting edge technology, tools, techniques and procedures to unravel the
                                    nature, likely causes and implications of medical problems and conditions.
                                </p>
                                <h3><span>Laboratory Services</span></h3>
                                <p>
                                    St. Ives offers an international standard 247-365 full laboratory services that
                                    serve the test needs of inpatients, outpatients, home-bound patients and emergency
                                    departments both within and outside the organization.
                                </p>
                                <h3><span>Radiology</span></h3>
                                <p>
                                    You can rely on us at St. Ives for the most up-to-date diagnostic facilities and the
                                    full complements of imaging procedures in all matters relating to X-rays, and other
                                    high-energy radiations in the effective diagnosis and treatments of diseases.
                                </p>
                                <h3><span>Screening Services</span></h3>
                                <p>
                                    From screening for chronic ailments such as Hepatitis and HIV to regular mammogram
                                    or pap smear, we offer a comprehensive array of screening procedures for the
                                    identification and diagnosis of various types of ailments and conditions. These are
                                    essential in the early detection, prevention and treatment of minor and major health
                                    impairments.
                                </p>
                                <p>
                                    Our diagnostic services offer vital contributions to both inpatients and
                                    outpatients, as well as other medical facilities, institutions and organizations in
                                    the effective management of health-related needs.
                                </p>
                                <p>
                                    For more on our laboratory and diagnostic services including our proprietary St.
                                    Ives Health Checks and assessment Program, please chat with us online or get in
                                    touch with us on any of our digital platforms. Or call our hotlines today…
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                                OB-GYN <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img class="mb-3" src="../../../../../assets/img/specialist/sp-ob-gyn.jpg" alt="OB-GYN" />
                                We have a team of expert gynecologists and obstetricians with unrivaled knowledge and
                                expertise in all matters of women and reproductive health.
                                <h3><span>Pre-Natal/ Ante-Natal/ Neo-Natal</span></h3>
                                <p>
                                    Therefore, our reputation in the areas of pre-natal, antenatal and neo-natal
                                    services is based on our reliable first-class deliveries in these highly essential
                                    specialties: from prenatal care where we prepare you for conception, to antenatal
                                    when we nurse you and your fetus through safe pregnancy with the best specialist
                                    services possible, all the way to neonatal stage where we provide the best
                                    protective care for your newborn (where required) and rehabilitative care for you…
                                </p>
                                <h3><span>Reproductive Health</span></h3>
                                <p>
                                    In addition, we are proficient in addressing reproductive health issues and
                                    conditions such as delayed conception, endometriosis, azoospermia etc. using a
                                    combination of the latest technology and the best of expertise.

                                    Our sister organization, <a href="">St. Ives IVF/Fertility Unit</a> boasts one of
                                    the highest
                                    <a href="success-rate">success rates</a> in Assisted Reproductive Techniques with
                                    its vast array of effective
                                    fertility and conception treatments.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree"
                                aria-expanded="false" aria-controls="collapseThree">
                                Internal Medicine <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img class="mb-3" src="../../../../../assets/img/specialist/sp-s-internal-med.jpg" alt="InternalMedicine" />
                                With a remarkable array of proficient specialists in most areas covering the diagnosis,
                                prevention and treatment of all aspects of diseases that affect the adult populace, our
                                internal Medicine practice is all-inclusive.

                                <h3><span>Cardiology</span></h3>
                                <h4>Echocardiography (ECG/EKG)</h4>
                                <p>
                                    Matters of the heart should not be taken lightly, that is why we take it serious at
                                    St. Ives. The Echocardiography or Electrocardiography (abbreviated as ECG or
                                    sometimes EKG) is a simple, noninvasive procedure that uses ultrasound waves to
                                    investigate the actions of the heart. It involves is the process of recording the
                                    electrical activity of the heart over a period of time using electrodes placed on
                                    the skin of the chest to assess the electrical and muscular functions of the heart
                                    and reveal whether or not it is working normally.
                                </p>
                                <p>
                                    Also known as cardiac imaging test, the ECG is required by those exhibiting symptoms
                                    of heart disease such as high blood pressure, chest pain or palpitations. However,
                                    the symptoms of heart diseases or problems go beyond those mentioned and are often
                                    not easily detected by the individual.
                                </p>
                                <p>
                                    Thus, you might need this test without even knowing. So, why not get in touch with
                                    us and book an appointment with our specialist for a professional assessment…
                                    remember, preventative medicine is better than curative, especially when it comes to
                                    matters of the heart.
                                </p>
                                <h3><span>Neurology</span></h3>
                                <h4><span>Electroencephalography (EEG)</span></h4>
                                <p>
                                    The EEG is a measurement of electronic activities and impulses in different parts of
                                    the brain. It is a test used to detect potential problems by tracking and recording
                                    brain wave patterns with the use of small, metal discs (electrodes) attached to the
                                    scalp.
                                </p>
                                <p>

                                    This non-invasive procedure can ultimately be used to confirm brain death but is
                                    primarily useful in the diagnosis and treatment of such conditions as seizures,
                                    epilepsy, head injuries, dizziness, headaches, brain tumors or sleeping
                                    disfunctions.
                                </p>
                                <p>
                                    How do you tell if you would require EEG? What should you do and not do before the
                                    test, or what can you consume so as not to influence the recording and mislead your
                                    specialists? For more on EEG and other brain related information and solution,
                                    contact us at St. Ives for better guidance on proper maintenance of this important
                                    part of the body.
                                </p>
                                <h3><span>Oncoloogy</span></h3>
                                <p>
                                    From cancer screening to customized palliative programs, we offer a comprehensive
                                    array of quality consultations and outpatient care for all types of cancer, at all
                                    stages of cancer and other tumor related conditions.
                                </p>
                                <h3><span>Urology</span></h3>
                                <p>
                                    We are proficient in the treatments of all matters relating to the functions and
                                    disorders of the urinary system such as urinary tract infections, stone diseases,
                                    urology cancers, voiding dysfunctions and pediatric urology.
                                </p>
                                <h3><span>Hematology</span></h3>
                                <p>
                                    We have the expertise to treat all blood-related conditions and diseases such as
                                    problems associated with the red blood cells, white blood cells, platelets, blood
                                    vessels, bone marrow, lymph nodes, spleen, and the proteins involved in bleeding and
                                    clotting.
                                </p>
                                <h3><span>Hepatology</span></h3>
                                <p>
                                    You can rely on us to treat and manage all conditions or disorders affecting the
                                    liver, gallbladder, biliary tree, and pancreas, including viral hepatitis and
                                    alcohol-related liver diseases.
                                </p>
                                <h3><span>Gastroenterology</span></h3>
                                <p>
                                    We have the expertise and procedures to effectively treat all disorders of the
                                    digestive system, which includes the stomach, esophagus, gallbladder, small, and
                                    large intestines, colon, pancreas, liver and rectum.
                                </p>
                                <h3><span>Anesthetics/Pain Management</span></h3>
                                <p>
                                    We run pain management programs entailing various techniques such as medications,
                                    physical therapy, massage therapy and nerve blocks to prevent, block or manage acute
                                    and chronic pain conditions.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour"
                                aria-expanded="false" aria-controls="collapseFour">
                                Pediatrics <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img class="mb-3" src="../../../../../assets/img/specialist/sp-pediatrics.jpg" alt="Pediatrics" />
                                Children, whether newborns, infants or adolescents, are most times difficult to
                                understand; therefore, their healthcare conditions and needs are different from that of
                                adults. Theirs, are delicate circumstances that require the interventions of skilled
                                specialists.
                                <p>
                                    St. Ives boasts some of the best child health specialists proficient in the
                                    effective management of children’s health and wellbeing, and abreast of modern
                                    techniques in the highly specialized practice.
                                </p>
                                <p>
                                    Our pediatrics services start with understanding: hence, are delivered in
                                    child-friendly atmosphere conducive to the peculiar whims of the kids in order to
                                    put them in their most natural environment and encourage them to thrive in good
                                    health of body and mind.
                                </p>
                                <p>
                                    St. Ives Child Health offerings are available to both children delivered in our
                                    hospitals or those from others. Kindly reach us today via any of our channels to
                                    book an appointment with any of our specialists and start the process to getting
                                    your child the best healthcare he or she deserves.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive"
                                aria-expanded="false" aria-controls="collapseFive">
                                Surgical Services <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img class="mb-3" src="../../../../../assets/img/specialist/sp-surgical-service.jpg" alt="SurgcalServices" />
                                With standard operating theaters, fit for all major surgical procedures, whether
                                invasive, minimally invasive or non-invasive; whether elective, urgent or emergency;
                                plus, expert surgeons second to none, we have the capacity and capabilities to perform
                                all types of surgical interventions, major or minor.

                                <h3><span>Major Surgeries</span></h3>
                                <p>
                                    We have seasoned specialists in the conduct of invasive operative procedures and all
                                    others involving the performance of extensive resection such as the removal of an
                                    organ, entering of a body cavity, or the alteration of the body’s anatomy.
                                </p>
                                <h3><span>Endoscopy</span></h3>
                                <p>
                                    Our specialist surgeons are equally proficient in the performance of first class
                                    minimally invasive or non-invasive surgical procedures and services for patients of
                                    all ages and conditions.
                                </p>

                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingSix">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSix"
                                aria-expanded="false" aria-controls="collapseSix">
                                Critical Care <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingSix"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img class="mb-3" src="../../../../../assets/img/specialist/sp-critical-care.jpg" alt="CriticalCare" />
                                We are capable, equipped and effective in the delivery of intensive care services and
                                treatments, particularly in the diagnosis and management of life-threatening conditions
                                that may require sophisticated life support and constant monitoring
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingSeven">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSeven"
                                aria-expanded="false" aria-controls="collapseSeven">
                                Admission & Recovery <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseSeven" class="collapse" role="tabpanel" aria-labelledby="headingSeven"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img class="mb-3" src="../../../../../assets/img/specialist/sp-adm-rec.jpg" alt="AdmissionRecovery" />

                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingEight">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseEight"
                                aria-expanded="false" aria-controls="collapseEight">
                                Public Health <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseEight" class="collapse" role="tabpanel" aria-labelledby="headingEight"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img class="mb-3" src="../../../../../assets/img/specialist/sp-healthcare.jpg" alt="PublicHealth" />
                                We are preventative medicine advocates and offer public health services, effective in
                                the prevention of diseases and control of epidemics with a view of prolonging life and
                                promoting human health and community wellbeing.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingNine">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseNine"
                                aria-expanded="false" aria-controls="collapseNine">
                                Emergency <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseNine" class="collapse" role="tabpanel" aria-labelledby="headingNine"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img class="mb-3" src="../../../../../assets/img/specialist/sp-emergency.jpg" alt="Emergency" />
                                With modern and functional set of ambulances, well equipped operating theatres, well
                                trained nursing stations and 24-hour specialist coverage at our hospitals, we are most
                                capable of addressing and handling hospital emergencies with adequate and definitive
                                care.
                            </div>
                        </div>
                    </div>
                </div>
                <a style="float: left;" class="btn btn-primary" routerLink="/specialist-home"> <span><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.354 10.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"/>
                  </svg></span> Go Back </a>
            </div>
            <!-- <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/fertility/stives1080.png" alt="img">
                </div>
            </div> -->
            <div class="col-lg-1"></div>
        </div>
    </div>
</section>

<app-footer></app-footer>