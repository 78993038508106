<section class="who-we-are ptb-100 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="section-title">
                    <span>Why we stand out</span>
                    <h2>Our<b> Success Rate</b> Statistics</h2>
                    <p>Why we are the best in the IVF industry.</p>
                    <a href="success-rate" class="btn btn-primary">Our Success Rate</a>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="content">
                    <h3> 2019 <b>Success Rate</b></h3>
                    <table style="width:100%" id="t2019">
                        <tr>
                            <th>Patient's Age</th>
                            <th>Under 34</th>
                            <th>35-37</th>
                            <th>38-40</th>
                            <th>41-42</th>
                            <th>Egg Donor</th>
                            <th>Total</th>
                        </tr>
                        <tr>
                            <td>Number of Transfers</td>
                            <td>69</td>
                            <td>97</td>
                            <td>88</td>
                            <td>92</td>
                            <td>334</td>
                            <td>680</td>
                        </tr>
                        <tr>
                            <td>Number of Clinical Pregnancy</td>
                            <td>39</td>
                            <td>50</td>
                            <td>35</td>
                            <td>22</td>
                            <td>195</td>
                            <td>341</td>
                        </tr>
                        <td>Success Rate</td>
                        <td>56.52%</td>
                        <td>51.54%</td>
                        <td>39.77%</td>
                        <td>23.9%</td>
                        <td>58.38%</td>
                        <td>50.1%</td>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>