<section class="funfacts-area-two ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <i class="icofont-checked"></i>
                    <h3 class="odometer" data-count="10">00</h3>
                    <p>Number of Transfer</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <i class="icofont-ui-user"></i>
                    <h3 class="odometer" data-count="30">00</h3>
                    <p>Professional Experts</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <i class="icofont-win-trophy"></i>
                    <h3 class="odometer" data-count="50.1">00</h3>
                    <p>Percent Success Rate</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <i class="icofont-crown-king"></i>
                    <h3 class="odometer" data-count="24">00</h3>
                    <p>Years Of Experience</p>
                </div>
            </div>
        </div>
    </div>
</section>