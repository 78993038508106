import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bloodbank-navbar',
  templateUrl: './bloodbank-navbar.component.html',
  styleUrls: ['./bloodbank-navbar.component.scss']
})
export class BloodbankNavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
