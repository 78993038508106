<section id="who-we-are" class="who-we-are ptb-100 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="section-title">
                    <span>Why we stand out</span>
                    <h2>Our<b> Success Rate</b> Statistics</h2>
                    <p>Why we are the best in the IVF industry.</p>
                    <a routerLink="/success-rate" class="btn btn-primary">Our Success Rate</a>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="content">
                    <h3> 2021 <b>Success Rate</b></h3>
                    <table style="width:100%" id="t2021">
                        <tr>
                            <th>Patient's Age</th>
                            <th>Under 34</th>
                            <th>35-37</th>
                            <th>38-40</th>
                            <th>41-42</th>
                            <th>Egg Donor</th>
                            <th>Total</th>
                        </tr>
                        <tr>
                            <td>Number of Transfers</td>
                            <td>78</td>
                            <td>116</td>
                            <td>101</td>
                            <td>103</td>
                            <td>402</td>
                            <td>800</td>
                        </tr>
                        <tr>
                            <td>Number of Clinical Pregnancy</td>
                            <td>51</td>
                            <td>69</td>
                            <td>48</td>
                            <td>29</td>
                            <td>283</td>
                            <td>480</td>
                        </tr>
                        <td>Success Rate</td>
                        <td>65.38%</td>
                        <td>59.48%</td>
                        <td>47.52%</td>
                        <td>28.15%</td>
                        <td>70.39%</td>
                        <td>60.00%</td>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>