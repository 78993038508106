<section class="page-title-area item-bg1">
    <div class="container">
        <a style="float: left;" class="btn" routerLink="/fertility-home"> <span><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.354 10.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"/>
          </svg></span> Go Back </a>
        <h2>Success Rate</h2>
    </div>
</section>



<section class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our <b>Sucess Rate</b></h2>
            <p>Our Annual Statistics</p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingEleven">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseEleven"
                                aria-expanded="false" aria-controls="collapseEleven">
                                2021 Sucess Rate<i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseEleven" class="collapse" role="tabpanel" aria-labelledby="headingEleven"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="content">
                                    <h3> 2021 <b>Success Rate</b></h3>
                                    <table style="width:100%" id="t2021">
                                        <tr>
                                            <th>Patient's Age</th>
                                            <th>Under 34</th>
                                            <th>35-37</th>
                                            <th>38-40</th>
                                            <th>41-42</th>
                                            <th>Egg Donor</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>Number of Transfers</td>
                                            <td>78</td>
                                            <td>116</td>
                                            <td>101</td>
                                            <td>103</td>
                                            <td>402</td>
                                            <td>800</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Clinical Pregnancy</td>
                                            <td>51</td>
                                            <td>69</td>
                                            <td>48</td>
                                            <td>29</td>
                                            <td>283</td>
                                            <td>480</td>
                                        </tr>
                                        <td>Success Rate</td>
                                        <td>65.38%</td>
                                        <td>59.48%</td>
                                        <td>47.52%</td>
                                        <td>28.15%</td>
                                        <td>70.39%</td>
                                        <td>60.00%</td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTen">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseTen"
                                aria-expanded="false" aria-controls="collapseTen">
                                2019 Sucess Rate<i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseTen" class="collapse" role="tabpanel" aria-labelledby="headingTen"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="content">
                                    <h3> 2019 <b>Success Rate</b></h3>
                                    <table style="width:100%" id="t2019">
                                        <tr>
                                            <th>Patient's Age</th>
                                            <th>Under 34</th>
                                            <th>35-37</th>
                                            <th>38-40</th>
                                            <th>41-42</th>
                                            <th>Egg Donor</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>Number of Transfers</td>
                                            <td>69</td>
                                            <td>97</td>
                                            <td>88</td>
                                            <td>92</td>
                                            <td>334</td>
                                            <td>680</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Clinical Pregnancy</td>
                                            <td>39</td>
                                            <td>50</td>
                                            <td>35</td>
                                            <td>22</td>
                                            <td>195</td>
                                            <td>341</td>
                                        </tr>
                                        <td>Success Rate</td>
                                        <td>56.52%</td>
                                        <td>51.54%</td>
                                        <td>39.77%</td>
                                        <td>23.9%</td>
                                        <td>58.38%</td>
                                        <td>50.1%</td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne"
                                aria-expanded="false" aria-controls="collapseOne">
                                2018 Sucess Rate<i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="content">
                                    <h3> 2018 <b>Success Rate</b></h3>
                                    <table style="width:100%" id="t2018">
                                        <tr>
                                            <th>Age</th>
                                            <th>U 34</th>
                                            <th>35-37</th>
                                            <th>38-40</th>
                                            <th>41-42</th>
                                            <th>Egg Donor</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>No of Transfers</td>
                                            <td>66</td>
                                            <td>94</td>
                                            <td>76</td>
                                            <td>91</td>
                                            <td>323</td>
                                            <td>650</td>
                                        </tr>
                                        <tr>
                                            <td>No of Clinical Pregnancy</td>
                                            <td>38/td>
                                            <td>44</td>
                                            <td>32</td>
                                            <td>23</td>
                                            <td>193</td>
                                            <td>330</td>
                                        </tr>
                                        <td>Success Rate</td>
                                        <td>57.57%</td>
                                        <td>46.8%</td>
                                        <td>42%</td>
                                        <td>25.27</td>
                                        <td>59.75%</td>
                                        <td>50.76%</td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                                2017 Success Rate <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="content">
                                    <h3> 2017 <b>Success Rate</b></h3>
                                    <table style="width:100%" id="t2017">
                                        <tr>
                                            <th>Patient's Age</th>
                                            <th>Under 34</th>
                                            <th>35-37</th>
                                            <th>38-40</th>
                                            <th>41-42</th>
                                            <th>Egg Donor</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>Number of Transfers</td>
                                            <td>55</td>
                                            <td>90</td>
                                            <td>90</td>
                                            <td>85</td>
                                            <td>280</td>
                                            <td>600</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Clinical Pregnancy</td>
                                            <td>30</td>
                                            <td>44</td>
                                            <td>40</td>
                                            <td>24</td>
                                            <td>150</td>
                                            <td>288</td>
                                        </tr>
                                        <td>Success Rate</td>
                                        <td>54.55%</td>
                                        <td>48.89%</td>
                                        <td>44.44%</td>
                                        <td>28.23%</td>
                                        <td>53.57%</td>
                                        <td>48%</td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree"
                                aria-expanded="false" aria-controls="collapseThree">
                                2016 Success Rate <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="content">
                                    <h3> 2016 <b>Success Rate</b></h3>
                                    <table style="width:100%" id="t2019">
                                        <tr>
                                            <th>Patient's Age</th>
                                            <th>Under 34</th>
                                            <th>35-37</th>
                                            <th>38-40</th>
                                            <th>41-42</th>
                                            <th>Egg Donor</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>Number of Transfers</td>
                                            <td>65</td>
                                            <td>91</td>
                                            <td>84</td>
                                            <td>81</td>
                                            <td>220</td>
                                            <td>541</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Clinical Pregnancy</td>
                                            <td>31</td>
                                            <td>47</td>
                                            <td>50</td>
                                            <td>23</td>
                                            <td>121</td>
                                            <td>272</td>
                                        </tr>
                                        <td>Success Rate</td>
                                        <td>47.69%</td>
                                        <td>51.65%</td>
                                        <td>59.52%</td>
                                        <td>28.89%</td>
                                        <td>55%</td>
                                        <td>50.27%</td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour"
                                aria-expanded="false" aria-controls="collapseFour">
                                2015 Success Rate <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="content">
                                    <h3> 2015 <b>Success Rate</b></h3>
                                    <table style="width:100%" id="t2019">
                                        <tr>
                                            <th>Patient's Age</th>
                                            <th>Under 34</th>
                                            <th>35-37</th>
                                            <th>38-40</th>
                                            <th>41-42</th>
                                            <th>Egg Donor</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>Number of Transfers</td>
                                            <td>59</td>
                                            <td>88</td>
                                            <td>78</td>
                                            <td>75</td>
                                            <td>200</td>
                                            <td>500</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Clinical Pregnancy</td>
                                            <td>28</td>
                                            <td>35</td>
                                            <td>28</td>
                                            <td>18</td>
                                            <td>116</td>
                                            <td>225</td>
                                        </tr>
                                        <td>Success Rate</td>
                                        <td>47.45%</td>
                                        <td>39.77%</td>
                                        <td>35.89%</td>
                                        <td>24%</td>
                                        <td>58%</td>
                                        <td>45%</td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive"
                                aria-expanded="false" aria-controls="collapseFive">
                                2014 Success Rate <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="content">
                                    <h3> 2014 <b>Success Rate</b></h3>
                                    <table style="width:100%" id="t2019">
                                        <tr>
                                            <th>Patient's Age</th>
                                            <th>Under 34</th>
                                            <th>35-37</th>
                                            <th>38-40</th>
                                            <th>41-42</th>
                                            <th>Egg Donor</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>Number of Transfers</td>
                                            <td>60</td>
                                            <td>70</td>
                                            <td>80</td>
                                            <td>80</td>
                                            <td>160</td>
                                            <td>450</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Clinical Pregnancy</td>
                                            <td>28</td>
                                            <td>27</td>
                                            <td>25</td>
                                            <td>20</td>
                                            <td>88</td>
                                            <td>191</td>
                                        </tr>
                                        <td>Success Rate</td>
                                        <td>46.66%</td>
                                        <td>38.57%</td>
                                        <td>35%</td>
                                        <td>25%</td>
                                        <td>55%</td>
                                        <td>42.44%</td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingSix">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSix"
                                aria-expanded="false" aria-controls="collapseSix">
                                2013 Success Rate <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingSix"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="content">
                                    <h3> 2013 <b>Success Rate</b></h3>
                                    <table style="width:100%" id="t2019">
                                        <tr>
                                            <th>Patient's Age</th>
                                            <th>Under 34</th>
                                            <th>35-37</th>
                                            <th>38-40</th>
                                            <th>41-42</th>
                                            <th>Egg Donor</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>Number of Transfers</td>
                                            <td>60</td>
                                            <td>65</td>
                                            <td>75</td>
                                            <td>78</td>
                                            <td>250</td>
                                            <td>428</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Clinical Pregnancy</td>
                                            <td>27</td>
                                            <td>23</td>
                                            <td>23</td>
                                            <td>20</td>
                                            <td>83</td>
                                            <td>176</td>
                                        </tr>
                                        <td>Success Rate</td>
                                        <td>45%</td>
                                        <td>35.3%</td>
                                        <td>30.6%</td>
                                        <td>25.6%</td>
                                        <td>55.3%</td>
                                        <td>41%</td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingSeven">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSeven"
                                aria-expanded="false" aria-controls="collapseSeven">
                                2012 Success Rate<i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseSeven" class="collapse" role="tabpanel" aria-labelledby="headingSeven"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="content">
                                    <h3> 2012 <b>Success Rate</b></h3>
                                    <table style="width:100%" id="t2019">
                                        <tr>
                                            <th>Patient's Age</th>
                                            <th>Under 34</th>
                                            <th>35-37</th>
                                            <th>38-40</th>
                                            <th>41-42</th>
                                            <th>Egg Donor</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>Number of Transfers</td>
                                            <td>55</td>
                                            <td>60</td>
                                            <td>55</td>
                                            <td>64</td>
                                            <td>130</td>
                                            <td>365</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Clinical Pregnancy</td>
                                            <td>23</td>
                                            <td>21</td>
                                            <td>17</td>
                                            <td>14</td>
                                            <td>71</td>
                                            <td>146</td>
                                        </tr>
                                        <td>Success Rate</td>
                                        <td>42%</td>
                                        <td>35%</td>
                                        <td>31%</td>
                                        <td>21%</td>
                                        <td>54.6%</td>
                                        <td>40%</td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingEight">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseEight"
                                aria-expanded="false" aria-controls="collapseEight">
                                2011 Success Rate <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseEight" class="collapse" role="tabpanel" aria-labelledby="headingEight"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="content">
                                    <h3> 2011 <b>Success Rate</b></h3>
                                    <table style="width:100%" id="t2019">
                                        <tr>
                                            <th>Patient's Age</th>
                                            <th>Under 34</th>
                                            <th>35-37</th>
                                            <th>38-40</th>
                                            <th>41-42</th>
                                            <th>Egg Donor</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>Number of Transfers</td>
                                            <td>550</td>
                                            <td>60</td>
                                            <td>50</td>
                                            <td>60</td>
                                            <td>100</td>
                                            <td>320</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Clinical Pregnancy</td>
                                            <td>20</td>
                                            <td>21</td>
                                            <td>15</td>
                                            <td>12</td>
                                            <td>54</td>
                                            <td>172</td>
                                        </tr>
                                        <td>Success Rate</td>
                                        <td>40%</td>
                                        <td>35%</td>
                                        <td>30%</td>
                                        <td>20%</td>
                                        <td>54%</td>
                                        <td>38%</td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3">
                        <div class="card-header" role="tab" id="headingNine">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseNine"
                                aria-expanded="false" aria-controls="collapseNine">
                                2010 Success Rate <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseNine" class="collapse" role="tabpanel" aria-labelledby="headingNine"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <div class="content">
                                    <h3> 2010 <b>Success Rate</b></h3>
                                    <table style="width:100%" id="t2019">
                                        <tr>
                                            <th>Patient's Age</th>
                                            <th>Under 34</th>
                                            <th>35-37</th>
                                            <th>38-40</th>
                                            <th>41-42</th>
                                            <th>Egg Donor</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>Number of Transfers</td>
                                            <td>42</td>
                                            <td>50</td>
                                            <td>48</td>
                                            <td>50</td>
                                            <td>70</td>
                                            <td>260</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Clinical Pregnancy</td>
                                            <td>15</td>
                                            <td>18</td>
                                            <td>15</td>
                                            <td>10</td>
                                            <td>35</td>
                                            <td>193</td>
                                        </tr>
                                        <td>Success Rate</td>
                                        <td>36%</td>
                                        <td>36%</td>
                                        <td>31%</td>
                                        <td>20%</td>
                                        <td>50%</td>
                                        <td>36%</td>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a style="float: left;" class="btn back-btn" routerLink="/fertility-home"> <span><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.354 10.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"/>
                  </svg></span> Go Back </a>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/fertility/stives1080.png" alt="img">
                </div>
            </div>
        </div>
    </div>
</section>