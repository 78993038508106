<section id="specialist-about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/fertility/stives_half1.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/fertility/stives_half2.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/fertility/about_half1.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/fertility/about_half2.png" alt="about">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About St Ives Specialist</span>
                    <h2>Learn about our <b>Specialist</b> Hospital</h2>
                    <p>St. Ives Specialist Hospital is the flagship brand of the St. Ives Healthcare Organization, a
                        leading private hospital group in Nigeria, comprising four distinct operations, running separately
                        and effectively meeting the targeted healthcare needs of their audience: the specialist hospital, a
                        family health practice, a fertility/IVF unit, and a pharmacy. </p>
                    <p>Founded in 1996 as a specialist women health practice, we have grown into a highly revered
                        multi-specialist healthcare provider, becoming renowned for reliable and effective solutions founded
                        on first class human resources and technology-driven medicare services, delivered in a most pleasant
                        environment. </p>
                    <p>St. Ives Specialist Hospital provides first class maintenance and improvement of health via the
                        reliable diagnosis, treatment, and prevention of diseases, illnesses, injuries, and other physical
                        and mental impairments. Our specialist hospitals are located in Ikoyi and Ikeja in Lagos.
                    </p>
                </div>
                    <a routerLink="/specialist-about-us" class="btn btn-primary">Know more</a>
                
            </div>
        </div>
    </div>
</section>