import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(public afs: AngularFirestore) { }

  pullBlogs() {
    return this.afs.collection('blogs', blogs => blogs.orderBy('date', 'desc').limit(8) );
  }

  pullSingleBlog(id) {
    return this.afs.collection('blogs', blogs => blogs.where('id', '==',id) );
  }
}
