import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blodbank-features',
  templateUrl: './blodbank-features.component.html',
  styleUrls: ['./blodbank-features.component.scss']
})
export class BlodbankFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Choose St Ives',
            title: 'Blood Banking',
            paragraphText: 'Help us save more lives'
            
        }
    ]

    singleFeatures: singleFeaturesContent[] = [
        {
            icon: 'icofont-patient-bed',
            title: 'Its Safe',
            paragraphText: 'We make use of clean equipments.'
        },
        {
            icon: 'icofont-blood',
            title: 'Express Delivery',
            paragraphText: 'Swift blood delivery service'
            
        },
        {
            icon: 'icofont-blood-drop',
            title: 'Government Registered',
            paragraphText: 'Approved by Lagos State Government'
        },
        
    ]

}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}

class singleFeaturesContent {
    icon : string;
    title : string;
    paragraphText : string;
}