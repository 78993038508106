<section id="contact" class="contact-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span>Get In Touch</span>
            <h2>Let's <b>Contact</b> Us</h2>
            <p>Feel free to get in touch with us through the provided channel</p>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="icofont-google-map"></i> <span>Location:</span>12, Salvation Road, Opebi, Ikeja, Lagos, Nigeria</li>
                                <li><i class="icofont-envelope"></i> <span>Email:</span><a href="mailto:info@stiveshealthcare.com">info@stiveshealthcare.com</a></li>
                                <li><i class="icofont-phone"></i> <span>Call:</span><a href="tel:+2347034246413,">(+234) 703 424 6413</a></li>
                                <li><i class="icofont-phone"></i> <span>Call:</span><a href="tel:+2347088727358">(+234) 708 872 7358</a></li>
                                <li><i class="icofont-facebook"></i> <span>Facebook:</span><a href="https://web.facebook.com/pg/stivesfertility/posts/" target="_blank">facebook.com/pg/stivesfertility/posts</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control"[(ngModel)]="name" placeholder="Name" name="NAME">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" [(ngModel)]="useremail" placeholder="Email Address" name="EMAIL">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="subject" placeholder="Subject" name="SUBJECT">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="number" placeholder="Phone Number" name="NUMBER">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea placeholder="Message" class="form-control" [(ngModel)]="message" name="MESSAGE" cols="30" rows="7"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button (click)="sendMail()" class="btn btn-primary">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>