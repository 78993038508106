import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(public afana: AngularFireAnalytics) { }

  ngOnInit(): void {
  }

  RecordAppointments() {
    this.afana.logEvent('Appointments', {date: new Date().getHours()})
  }

  RecordFamily() {
    this.afana.logEvent('Family', {date: new Date().getHours()})
  }

  RecordSpecialist() {
    this.afana.logEvent('Specialist', {date: new Date().getHours()})
  }

  RecordFertility() {
    this.afana.logEvent('Fertility', {date: new Date().getHours()})
  }

  RecordPharmacy() {
    this.afana.logEvent('Pharmacy', {date: new Date().getHours()})
  }
  
  RecordBloodBank() {
    this.afana.logEvent('BloodBank', {date: new Date().getHours()})
  }

 

 
 
}
