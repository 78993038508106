import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specialist-faq',
  templateUrl: './specialist-faq.component.html',
  styleUrls: ['./specialist-faq.component.scss']
})
export class SpecialistFaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
