<section id="family-about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/fertility/stives_half1.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/fertility/stives_half2.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/family/family01.jpg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/family/family02.jpg" alt="about">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About St Ives Family</span>
                    <h2>Learn about our <b>Family</b> Hospital</h2>
                    <p> St. Ives is a leading private hospital group in Nigeria. </p>

                    <p>St. Ives started out in a 3-bedroom apartment somewhere in Ikeja in 1996, we have since grown
                        into a
                        highly revered multi-specialist healthcare provider, becoming renowned for reliable and
                        effective
                        solutions founded on first class human resources and technology-driven medicare services,
                        delivered
                        in a most pleasant environment.
                    </p>
                    <p>The success of our healthcare practice has seen the organization develop across 3 distinct
                        operations at separate locations, offering
                    </p>

                    <ul>
                        <li>Specialists hospital services </li>
                        <li>General practice family clinics, </li>
                        <li>IVF/Fertility unit </li>
                    </ul>
                    <p>The St. Ives Family unit recently opened another branch in Ibarra Housing Estate, Ogun State,
                        fully
                        equipped to offer general practice family services along with assisted reproductive services as
                        part
                        of our long-haul target of taking the St. Ives Healthcare franchise across the nation and beyond
                        with a view to making our reliable “Total Healthcare” available and accessible to as many
                        families
                        as possible.
                    </p>
                </div>
                <a routerLink="/family-about-us" class="btn btn-primary">Know more</a>
            </div>
        </div>
    </div>
</section>