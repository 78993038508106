<!-- <app-specialist-navbar></app-specialist-navbar> -->

<section class="page-title-area item-bg1">
    <div class="container">
        <a style="float: left;" class="btn back-btn" routerLink="/specialist-home"> <span><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.354 10.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"/>
          </svg></span> Go Back </a>
        <h2 class="white">About Us</h2>
    </div>
</section>



<section class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span>Statistics</span> -->
            <h2> St Ives <b>Specialist Hospital</b></h2>
            <!-- <p>Our Annual Statistics</p> -->
        </div>
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10 col-md-12">                
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne"
                                aria-expanded="false" aria-controls="collapseOne">
                                Introduction<i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img src="assets/img/specialist/sp-about.png" alt="allStaff" />
                                <p>
                                    St. Ives Specialist Hospital is the flagship brand of the St. Ives Healthcare
                                    Organization, a leading private hospital group in Nigeria, comprising four distinct
                                    operations, running separately and effectively meeting the targeted healthcare needs
                                    of their audience: the specialist hospital, a family health practice, a
                                    fertility/IVF unit, and a pharmacy.
                                </p>
                                <p>
                                    Founded in 1996 as a specialist women health practice, we have grown into a highly
                                    revered multi-specialist healthcare provider, becoming renowned for reliable and
                                    effective solutions founded on first class human resources and technology-driven
                                    medicare services, delivered in a most pleasant environment.
                                </p>
                                <p>
                                    St. Ives Specialist Hospital provides first class maintenance and improvement of
                                    health via the reliable diagnosis, treatment, and prevention of diseases, illnesses,
                                    injuries, and other physical and mental impairments. Our specialist hospitals are
                                    located in Ikoyi and Ikeja in Lagos.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                                Vision & Mission <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img src="assets/img/specialist/sp-vision.jpg" alt="Vision" />
                                <h3><span>Vision</span></h3>
                                <blockquote>To be a healthcare organization of first choice for women, children and
                                    the family.</blockquote>

                                <h3><span>Mission</span></h3>
                                <blockquote>To offer a dedicated consultant-led, technology driven healthcare
                                    services to women, children and the family in a friendly environment.
                                </blockquote>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree"
                                aria-expanded="false" aria-controls="collapseThree">
                                First Class Specialist <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img src="../../../../../assets/img/specialist/sp-1stclass.jpg" alt="1stClassSpecialist" />
                                Central to all our operations is a steadfast commitment to professionalism; and this
                                starts with our human resources. This is evident in our remarkable success in the last
                                two decades, driven by a group of best in class specialist consultants, delivering the
                                best in care in all areas of internal medicine, family practice and child health.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour"
                                aria-expanded="false" aria-controls="collapseFour">
                                First Class Facilities <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img src="../../../../../assets/img/specialist/sp-facilities.jpg" alt="1stClassFacilities" />
                                St. Ives hospitals are well equipped with all modern facilities, equipment and the
                                latest technology required for the delivery of world-class reliable healthcare
                                solutions. These include operating theaters fit for all major surgical procedures, full
                                and functional laboratory and radio-diagnostic units, comfortable admission facilities,
                                as well as well-stocked pharmacies.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive"
                                aria-expanded="false" aria-controls="collapseFive">
                                Total Healthcare <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img src="../../../../../assets/img/specialist/sp-healthcare.jpg" alt="Healthcare" />
                                Our promise is to effective Total Healthcare and wellbeing. Thus, you can be assured of
                                all effort being made, and all expertise and resources being committed towards providing
                                the best possible standards and satisfactory medical services at all St. Ives hospitals.

                                <br />

                                We also provide prompt arrangements for in-house consultation and treatment by
                                Sub-specialty consultants, as well as reliable referrals to affiliate specialists and
                                consultants abroad – at clients’ request.
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingSix">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSix"
                                aria-expanded="false" aria-controls="collapseSix">
                                Core Values<i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingSix"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <img src="../../../../../assets/img/specialist/sp-core.jpg" alt="allStaff" />
                                <h3><span>Core Values</span></h3>
                                <p>
                                    Our existence as an organization and our conducts as professionals are founded on
                                    and guided by the following principles:
                                </p>
                                <h3><span>Life</span></h3>
                                <p>
                                    We believe that the human life is sacrosanct and hence, our expertise, experience,
                                    facilities and resources are dedicated to saving and managing the lives in our care,
                                    the best ways possible for the best quality of life possible.
                                </p>
                                <h3><span>Family</span></h3>
                                <p>
                                    We are enthusiastic about the family as a unit. We believe it is the most important
                                    institution in the society because it is the foundation for the preservation,
                                    propagation and prosperity of the human race. After all, a functional family starts
                                    with a healthy family.
                                </p>
                                <h3><span>Professionalism</span></h3>
                                <p>
                                    Our growing clientele has seen our operations expanding year on year since
                                    inception. This is testament to our diligence and uncompromising commitment to
                                    standards in personnel, facilities and practice.
                                </p>
                                <h3><span>Ethics</span></h3>
                                <p>
                                    We are responsible corporate citizens. Therefore, we are guided by the highest
                                    ethical, moral and legal standards as we go the extra mile in making our healthcare
                                    practice and deliveries effective for our patients.
                                </p>
                                <h3><span>Relationship</span></h3>
                                <p>
                                    We take our clients as family and friends. By entrusting us with the most sensitive
                                    aspects of their lives, we in turn value and treat them with the utmost care and
                                    respect, maintaining constant communication and providing relevant information and
                                    guide on all our efforts in getting them to their desired healthcare destinations.
                                <h3><span>Excellence</span></h3>
                                <p>
                                    For us, anything worth doing is worth doing well. From our people to our facilities,
                                    and the procedures and processes we deploy in our operations and service delivery,
                                    we ensure and guarantee nothing less than the best.
                                </p>
                            </div>
                        </div>
                    </div>
                    <a routerLink="/specialist-home" class="btn btn-primary"> <span><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.354 10.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"/>
                      </svg></span> Go Back</a>
                </div>
            </div>
            <!-- <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/fertility/stives1080.png" alt="img">
                </div>
            </div> -->
            <div class="col-lg-1"></div>
        </div>
    </div>
</section>

<app-footer></app-footer>