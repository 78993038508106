<section class="subscribe-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="newsletter">
            <h2>Join Our <b>Newsletter</b></h2>
            <form class="newsletter-form">
                <input type="text" [(ngModel)]="useremail" id="EMAIL" placeholder="Your Email Address" name="EMAIL" class="form-control">
                <button (click)="newsletterSubscribe()">Subscribe</button>
            
            </form>
            <h4 *ngIf="newsletterSubscription" style="margin-top: 20px;">you have successfully subscribed to our <b>Newsletter</b></h4>
        </div>
    </div>
</section>