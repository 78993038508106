
  <div class="home-slides owl-carousel owl-theme">
    <div class="main-banner spitem-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <span></span>
                        
                        
                        <h1>
                        <ngx-typed-js [strings]="['Welcome to St. Ives <br/> Healthcare Organization ']"
                            [shuffle]="false" [typeSpeed]="40" [loop]="true">

                            <span class="typing"></span>

                        </ngx-typed-js>
                        </h1>
                        <p></p>
                    </div>
                </div>
                
                <div class="container d-none d-lg-block d-xl-block">
                    <div class="row overlay text-center ">
                        <div class="col-lg-2">
                            <div (click)="RecordAppointments()" class="box-content">
                                <a href="http://portal.stiveshealthcare.com">
                                    <img src="../../../../assets/img/icon/book.png" />
                                    <h4>Book a Date</h4>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div (click)="RecordFamily()" class="box-content">
                                <a routerLink="family-home">
                                    <img src="../../../../assets/img/icon/family (1).png" />
                                    <h4>Family</h4>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div (click)="RecordFertility()" class="box-content">
                                <a routerLink="fertility-home">
                                    <img src="../../../../assets/img/icon/pregnant.png" />
                                    <h4>Fertility</h4>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div (click)="RecordSpecialist()" class="box-content">
                                <a routerLink="specialist-home">
                                    <img src="../../../../assets/img/icon/doctor.png" />
                                    <h4>Specialist</h4>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div (click)="RecordPharmacy()" class="box-content">
                                <a href="http://saintivespharmacy.com">
                                    <img src="../../../../assets/img/icon/pharmacy.png" />
                                    <h4>Pharmacy</h4>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div (click)="RecordBloodBank()"class="box-content">
                                <a routerLink="blood-bank">
                                    <img src="../../../../assets/img/icon/blood-bag.png" />
                                    <h4>Blood Bank</h4>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="mobile d-block d-md-none">
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-md-4 col-sm-4 m-content">
                                <div class="m-box-content">
                                    <a href="">
                                        <img src="../../../../assets/img/icon/book.png" />
                                        <h6>Book a Date</h6>
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 m-content">
                                <div class="m-box-content">
                                    <a routerLink="family-home">
                                        <img src="../../../../assets/img/icon/family (1).png" />
                                        <h6>Family</h6>
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 m-content">
                                <div class="m-box-content">
                                    <a routerLink="fertility-home">
                                        <img src="../../../../assets/img/icon/pregnant.png" />
                                        <h6>Fertility</h6>
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 m-content">
                                <div class="m-box-content">
                                    <a routerLink="specialist-home">
                                        <img src="../../../../assets/img/icon/doctor.png" />
                                        <h6>Specialist</h6>
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 m-content">
                                <div class="m-box-content">
                                    <a href="http://saintivespharmacy.com">
                                        <img src="../../../../assets/img/icon/pharmacy.png" />
                                        <h6>Pharmacy</h6>
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 m-content">
                                <div class="m-box-content">
                                    <a href="">
                                        <img src="../../../../assets/img/icon/blood-bag.png" />
                                        <h6>Blood Bank</h6>
                                    </a>
                                </div>
                            </div>
                
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
    <div class="main-banner spitem-bg3">
        <div class="d-table">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <span></span>
                            <h1>
                                <!-- leading Multi-Specialist <br/> Hospital in Nigeria']" -->
                            <ngx-typed-js [strings]="['A safecare 4star accredited/certified Hospital']"
                                [shuffle]="false" [typeSpeed]="60" [loop]="true">
    
                                <span class="typing"></span>
    
                            </ngx-typed-js>
                            </h1>
                            <p></p>
                        </div>
                    </div>
                    <div class="container d-none d-lg-block d-xl-block">
                        <div class="row overlay text-center ">
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a href="portal.">
                                        <img src="../../../../assets/img/icon/book.png" />
                                        <h4>Book a Date</h4>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a routerLink="family-home">
                                        <img src="../../../../assets/img/icon/family (1).png" />
                                        <h4>Family</h4>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a routerLink="fertility-home">
                                        <img src="../../../../assets/img/icon/pregnant.png" />
                                        <h4>Fertility</h4>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a routerLink="specialist-home">
                                        <img src="../../../../assets/img/icon/doctor.png" />
                                        <h4>Specialist</h4>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a href="http://saintivespharmacy.com">
                                        <img src="../../../../assets/img/icon/pharmacy.png" />
                                        <h4>Pharmacy</h4>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a routerLink="blood-bank">
                                        <img src="../../../../assets/img/icon/blood-bag.png" />
                                        <h4>Blood Bank</h4>
                                    </a>
    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mobile d-block d-md-none">
                        <div class="container">
                            <div class="row text-center">
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        <a href="">
                                            <img src="../../../../assets/img/icon/book.png" />
                                            <h6>Book a Date</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        <a routerLink="family-home">
                                            <img src="../../../../assets/img/icon/family (1).png" />
                                            <h6>Family</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        <a routerLink="fertility-home">
                                            <img src="../../../../assets/img/icon/pregnant.png" />
                                            <h6>Fertility</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        <a routerLink="specialist-home">
                                            <img src="../../../../assets/img/icon/doctor.png" />
                                            <h6>Specialist</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        <a href="http://saintivespharmacy.com">
                                            <img src="../../../../assets/img/icon/pharmacy.png" />
                                            <h6>Pharmacy</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        <a href="">
                                            <img src="../../../../assets/img/icon/blood-bag.png" />
                                            <h6>Blood Bank</h6>
                                        </a>
                                    </div>
                                </div>
                    
                            </div>
                        </div>
                    </div>
    
    
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner spitem-bg1">
        <div class="d-table">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <span></span>
                            <h1>
                            <ngx-typed-js [strings]="['Dedicated to Women, Children <br/> and Family Health']"
                                [shuffle]="false" [typeSpeed]="60" [loop]="true">
    
                                <span class="typing"></span>
    
                            </ngx-typed-js>
                            </h1>
                            <p></p>
                        </div>
                    </div>
                    <div class="container d-none d-lg-block d-xl-block">
                        <div class="row overlay text-center ">
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a href="portal.">
                                        <img src="../../../../assets/img/icon/book.png" />
                                        <h4>Book a Date</h4>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a routerLink="family-home">
                                        <img src="../../../../assets/img/icon/family (1).png" />
                                        <h4>Family</h4>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a routerLink="fertility-home">
                                        <img src="../../../../assets/img/icon/pregnant.png" />
                                        <h4>Fertility</h4>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a routerLink="specialist-home">
                                        <img src="../../../../assets/img/icon/doctor.png" />
                                        <h4>Specialist</h4>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a href="http://saintivespharmacycom">
                                        <img src="../../../../assets/img/icon/pharmacy.png" />
                                        <h4>Pharmacy</h4>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="box-content">
                                    <a routerLink="blood-bank">
                                        <img src="../../../../assets/img/icon/blood-bag.png" />
                                        <h4>Blood Bank</h4>
                                    </a>
    
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="mobile d-block d-md-none">
                        <div class="container">
                            <div class="row text-center">
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        <a href="">
                                            <img src="../../../../assets/img/icon/book.png" />
                                            <h6>Book a Date</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        <a routerLink="family-home">
                                            <img src="../../../../assets/img/icon/family (1).png" />
                                            <h6>Family</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        <a routerLink="fertility-home">
                                            <img src="../../../../assets/img/icon/pregnant.png" />
                                            <h6>Fertility</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        <a routerLink="specialist-home">
                                            <img src="../../../../assets/img/icon/doctor.png" />
                                            <h6>Specialist</h6>
                                        </a>
                                    </div>
                                </div>
                                
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        
                                        <a href="http://saintivespharmacy.com">
                                            <img src="../../../../assets/img/icon/pharmacy.png" />
                                            <h6>Pharmacy</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 m-content">
                                    <div class="m-box-content">
                                        <a href="">
                                            <img src="../../../../assets/img/icon/blood-bag.png" />
                                            <h6>Blood Bank</h6>
                                        </a>
                                    </div>
                                </div>
                    
                            </div>
                        </div>
                    </div>
    
    
    
                </div>
            </div>
        </div>
    </div>
    
    <!-- <div class="main-banner spitem-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1>Could soon be in your <b>Loving Arms</b> </h1>
                        <p> </p>
                        <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

