<!-- <app-family-navbar></app-family-navbar>

<section class="page-title-area item-bg1">
    <div class="container">
        <h2 class="white">Our Facilities</h2>
    </div>
</section> -->


<section id="family-facilities" class="portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Technologies we use</span>
            <h2>Our <b>Facilities</b></h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>
        <!-- <div class="shorting-menu">
            <button class="filter" data-filter="all">All</button>
            <button class="filter" data-filter=".design">Design</button>
            <button class="filter" data-filter=".seo">SEO</button>
            <button class="filter" data-filter=".branding">Branding</button>
            <button class="filter" data-filter=".print">Print</button>
            <button class="filter" data-filter=".video">Video</button>
        </div> -->
        <div class="shorting">
            <div class="row">
                <div class="col-lg-4 col-md-6 mix design">
                    <div class="single-work">
                        <a href="assets/img/family/x-ray.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="assets/img/family/x-ray.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>X Ray Machine</h3>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix print seo">
                    <div class="single-work">
                        <a href="assets/img/family/pulse-oximeter.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../../assets/img/family/pulse-oximeter.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Pulse Oximetry</h3>
                                <!-- <span>Psd</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix video branding">
                    <div class="single-work">
                        <a href="assets/img/family/ECG.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../../assets/img/family/ECG.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Electrocardiograph (ECG) Machine</h3>
                                <!-- <span>Pencil</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix design print">
                    <div class="single-work">
                        <a href="assets/img/family/Cardiotocography.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../../assets/img/family/Cardiotocography.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Cardiotocograph (CTG)</h3>
                                <!-- <span>Art</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix branding video">
                    <div class="single-work">
                        <a href="assets/img/family/nebulizer.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../../assets/img/family/nebulizer.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>A Nebulizer</h3>
                                <!-- <span>Business</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix seo design">
                    <div class="single-work">
                        <a href="assets/img/family/4D-Ultrasound.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../../assets/img/family/4D-Ultrasound.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>4D Ultrasound</h3>
                                <!-- <span>Design</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix seo design">
                    <div class="single-work">
                        <a href="assets/img/family/incubator.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../../assets/img/family/incubator.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Incubator</h3>
                                <!-- <span>Design</span> -->
                            </div>
                        </div>
                    </div>
                </div><div class="col-lg-4 col-md-6 mix seo design">
                    <div class="single-work">
                        <a href="assets/img/family/mammography.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../../assets/img/family/mammography.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Mammography</h3>
                                <!-- <span>Design</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix seo design">
                    <div class="single-work">
                        <a href="assets/img/family/resuscitaire.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../../assets/img/family/resuscitaire.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Resuscitaire</h3>
                                <!-- <span>Design</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix seo design">
                    <div class="single-work">
                        <a href="assets/img/family/phototherapy.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../../assets/img/family/phototherapy.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Phototherapy</h3>
                                <!-- <span>Design</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix seo design">
                    <div class="single-work">
                        <a href="assets/img/family/theatre.jpg" class="popup-btn"></a>
                        <div class="work-image">
                            <img src="../../../../../assets/img/family/theatre.jpg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Modern Operating Theaters</h3>
                                <!-- <span>Design</span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <app-footer></app-footer> -->