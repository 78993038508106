import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-family-about',
  templateUrl: './family-about.component.html',
  styleUrls: ['./family-about.component.scss']
})
export class FamilyAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
