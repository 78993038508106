<section id="specialist-services" class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Services</span>
            <h2>Our <b>Specialist Services</b></h2>
            <p>Services we offer at St.Ives Fertility</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne"
                                aria-expanded="false" aria-controls="collapseOne">
                                Medical Diagnstics<i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                The accurate identification of ailments and impairments is fundamental to effective
                                healthcare solutions delivery; therefore, we offer a full complementary set of
                                distinctive medical diagnostic services i.e. the processes and techniques of determining
                                which disease or condition explains a person’s symptoms and signs.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                                OB-GYN <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                OB-GYN Checks includes;
                                <ul>
                                    <li>Pre-natal</li>
                                    <li>Ante-natal</li>
                                    <li>Neo-natal</li>
                                    <li>Reproductive Health</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree"
                                aria-expanded="false" aria-controls="collapseThree">
                                Internal Medicine <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                With a remarkable array of proficient specialists in most areas covering the diagnosis,
                                prevention and treatment of all aspects of diseases that affect the adult populace, our
                                internal Medicine practice is all-inclusive.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour"
                                aria-expanded="false" aria-controls="collapseFour">
                                Pediatrics <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                Children, whether newborns, infants or adolescents, are most times difficult to
                                understand; therefore, their healthcare conditions and needs are different from that of
                                adults. Theirs, are delicate circumstances that require the interventions of skilled
                                specialists.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive"
                                aria-expanded="false" aria-controls="collapseFive">
                                Surgery <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                With standard operating theaters, fit for all major surgical procedures, whether
                                invasive, minimally invasive or non-invasive; whether elective, urgent or emergency;
                                plus, expert surgeons second to none, we have the capacity and capabilities to perform
                                all types of surgical interventions, major or minor.
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingSix">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSix"
                                aria-expanded="false" aria-controls="collapseSix">
                                Critical Care <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingSix"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingSeven">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSeven"
                                aria-expanded="false" aria-controls="collapseSeven">
                                Admission + Recovery <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseSeven" class="collapse" role="tabpanel" aria-labelledby="headingSeven"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header" role="tab" id="headingEight">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseEight"
                                aria-expanded="false" aria-controls="collapseEight">
                                Public Health <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseEight" class="collapse" role="tabpanel" aria-labelledby="headingEight"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                
                            </div>
                        </div>
                    </div>                    
                </div>

                <a routerLink="/specialist-services" class="btn btn-primary">Know more</a>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/fertility/stives1080.png" alt="img">
                </div>
            </div>
        </div>
    </div>
</section>