<section id="bloodbank-about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/bloodbank/blood010.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/bloodbank/blood011.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/bloodbank/blood013.png" alt="about">
                        </div>
                    </div>
 
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/bloodbank/bloodbike.jpg" alt="about">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>St Ives Blood Bank</span>
                    <h2>What is <b>Blood Bank</b></h2>
                    <p>Blood banking is the process that takes place in the lab to make sure that donated blood, or
                        blood products, are safe before they are used in blood transfusions and other medical
                        procedures. Blood banking includes typing the blood for transfusion and testing for infectious
                        diseases. </p>
                    <h2>Who are the <b>Blood donors</b></h2>
                    <p>Most blood donors are volunteers. However, sometimes, a patient may want to donate blood a couple
                        of weeks before undergoing surgery, so that his or her blood is available in case of a blood
                        transfusion. Donating blood for yourself is called an autologous donation. Volunteer blood
                        donors must pass certain criteria, including the following:</p>
                    <ul>
                        <li>Must be at least 16 years of age, or in accordance with state law</li>
                        <li>Must be in good health</li>
                        <li>Must weigh at least 110 pounds</li>
                        <li>Must pass the physical and health history exam given before donation</li>
                    </ul>
                    <p>Some states permit people younger than 16 or 17 years to donate blood, with parental consent.
                    </p>

                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-3">

            </div>
            <div class="col-lg-6">
                <div class="about-content">
                    <h2>What are the <b>Blood types? </b></h2>
                    <p>
                        According to the American Association of Blood Banks, distribution of blood types in the U.S.
                        includes the following:
                    </p>
                    <ul>
                        <li>O Rh-positive - 39% </li>
                        <li>A Rh-positive - 31% </li>
                        <li>B Rh-positive - 9% </li>
                        <li>O Rh-negative - 9% </li>
                        <li>A Rh-negative - 6% </li>
                        <li>AB Rh-positive - 3% </li>
                        <li>B Rh-negative - 2% </li>
                        <li>AB Rh-negative - 1% </li>
                    </ul>
                </div>
            </div>
            <div class="3">

            </div>
        </div>
    </div>
</section>