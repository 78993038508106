import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { BlogOneComponent } from './components/pages/blog-one/blog-one.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import {SpecialistBlogDetailsComponent } from './components/pages/specialist-blog-details/specialist-blog-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { OurServicesComponent } from './components/pages/our-services/our-services.component';
import { SuccessRateComponent } from './components/layouts/success-rate/success-rate.component';
import { SpecialistHomeComponent } from './components/pages/specialist-home/specialist-home.component';
import { SpecialistAboutUsComponent } from './components/layouts/specialist/specialist-about-us/specialist-about-us.component';
import { SpecialistAllServicesComponent } from './components/layouts/specialist/specialist-all-services/specialist-all-services.component';
import { LandingComponent } from './components/pages/landing/landing.component';
import { BloodbankHomeComponent } from './components/pages/bloodbank-home/bloodbank-home.component';
import { FamilyHomeComponent } from './components/pages/family-home/family-home.component';
import { FamilyAboutUsComponent } from './components/layouts/family/family-about-us/family-about-us.component';
import { FamilyFacilitiesComponent } from './components/layouts/family/family-facilities/family-facilities.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';


const routes: Routes = [
    {path: '', component: HomeTwoComponent},
    {path: 'fertility-home', component: HomeTwoComponent},
    {path: 'blog-1/:id', component: BlogOneComponent},
    {path: 'blog-2', component: BlogTwoComponent},
    {path: 'blog-details/:id', component: BlogDetailsComponent},
    {path: 'specialist-blog-details/:id', component: SpecialistBlogDetailsComponent},
    {path: 'fertility-services', component: OurServicesComponent},
    {path: 'success-rate', component: SuccessRateComponent},
    {path: 'specialist-home', component: SpecialistHomeComponent},
    {path: 'specialist-about-us', component: SpecialistAboutUsComponent},
    {path: 'specialist-services', component: SpecialistAllServicesComponent},
    {path: 'blood-bank', component: BloodbankHomeComponent},
    {path: 'family-home', component: FamilyHomeComponent},
    {path: 'family-about-us', component: FamilyAboutUsComponent},
    {path: 'family-facilities', component: FamilyFacilitiesComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'privacy', component: PrivacyComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }