<!-- <app-family-navbar></app-family-navbar> -->


        <section id="family-about-us" class="page-title-area item-bg-about">
            <div class="container">
                <a style="float: left;" class="btn back-btn" routerLink="/family-home"> <span><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.354 10.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"/>
                  </svg></span> Go Back </a>
                <h2 class="white">About Us</h2>

            </div>
        </section>
        
        
        
        <section class="faq-area ptb-100">
            <div class="container">
                <div class="section-title">
                    <!-- <span>Statistics</span> -->
                    <h2> St Ives <b>Family Clinic</b></h2>
                    <!-- <p>Our Annual Statistics</p> -->
                </div>
                <div class="row">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-10 col-md-12">
                        <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                            <div class="card">
                                <div class="card-header" role="tab" id="headingOne">
                                    <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne"
                                        aria-expanded="false" aria-controls="collapseOne">
                                        Introduction<i class="icofont-dotted-down"></i>
                                    </a>
                                </div>
        
                                <!-- <div class="row">
                                    <button (click)="firstModal.open()">modal with custom header content and footer</button>
                                    <modal #firstModal>
                                        <modal-header>
                                            <h1>I am first modal</h1>
                                        </modal-header>
                                        <modal-content>
                                            This modal has its own header, content and footer.
                                        </modal-content>
                                        <modal-footer>
                                            <button class="btn btn-primary" (click)="firstModal.close()">okay!</button>
                                        </modal-footer>
                                    </modal>
                                </div> -->
        
                                <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne"
                                    data-parent="#accordionEx">
                                    <div class="card-body">
                                        <img src="assets/img/family/allstaffs.jpg" alt="allStaff" />
                                        <div class="about-content">
                                            <p> St. Ives is a leading private hospital group in Nigeria. </p>
        
                                            <p>St. Ives started out in a 3-bedroom apartment somewhere in Ikeja in 1996, we have
                                                since grown
                                                into a
                                                highly revered multi-specialist healthcare provider, becoming renowned for
                                                reliable and
                                                effective
                                                solutions founded on first class human resources and technology-driven medicare
                                                services,
                                                delivered
                                                in a most pleasant environment.
                                            </p>
                                            <p>The success of our healthcare practice has seen the organization develop across 3
                                                distinct
                                                operations at separate locations, offering
                                            </p>
        
                                            <ul>
                                                <li>Specialists hospital services </li>
                                                <li>General practice family clinics, </li>
                                                <li>IVF/Fertility unit </li>
                                            </ul>
                                            <p>The St. Ives Family unit recently opened another branch in Ibarra Housing Estate,
                                                Ogun State,
                                                fully
                                                equipped to offer general practice family services along with assisted
                                                reproductive services as
                                                part
                                                of our long-haul target of taking the St. Ives Healthcare franchise across the
                                                nation and beyond
                                                with a view to making our reliable “Total Healthcare” available and accessible
                                                to as many
                                                families
                                                as possible.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" role="tab" id="headingTwo">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo"
                                        aria-expanded="false" aria-controls="collapseTwo">
                                        Vision & Mission <i class="icofont-dotted-down"></i>
                                    </a>
                                </div>
                                <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
                                    data-parent="#accordionEx">
                                    <div class="card-body">
                                        <img src="assets/img/family/vision.jpg" alt="Vision" />
                                        <h3><span>Vision</span></h3>
                                        <blockquote>To be a healthcare organization of first choice for women, children and
                                            the family.</blockquote>
        
                                        <h3><span>Mission</span></h3>
                                        <blockquote>To offer a dedicated consultant-led, technology driven healthcare
                                            services to women, children and the family in a friendly environment.
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" role="tab" id="headingThree">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree"
                                        aria-expanded="false" aria-controls="collapseThree">
                                        DEDICATED PROFESSIONALS <i class="icofont-dotted-down"></i>
                                    </a>
                                </div>
                                <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                                    data-parent="#accordionEx">
                                    <div class="card-body">
                                        <img src="../../../../../assets/img/family/staff.jpg" alt="DedicatedStaff" />
                                        Central to all our operations is a steadfast commitment to professionalism; and this
                                        starts with our human resources.
                                        <p>
                                            Our family unit offers dedicated specialist services in all matters of women,
                                            children
                                            and family health, managed and delivered by a group of consultants in Obstetrics and
                                            Gynecology, Pediatrics, Medicine and Surgery who are highly qualified and abreast of
                                            modern trends and developments in their various specialties.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" role="tab" id="headingFour">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour"
                                        aria-expanded="false" aria-controls="collapseFour">
                                        TECHNOLOGY DRIVEN PRACTICE AND TOTAL HEALTHCARE <i class="icofont-dotted-down"></i>
                                    </a>
                                </div>
                                <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour"
                                    data-parent="#accordionEx">
                                    <div class="card-body">
                                        <img src="../../../../../assets/img/family/tech-driven.jpg"
                                            alt="1stClassFacilities" />
                                        <h3>Technology Driven Practice</h3>
        
                                        <p>St. Ives hospitals are well equipped with all modern facilities required for the
                                        delivery of world-class reliable healthcare solutions.
                                        These include operating theaters fit for all major surgical procedures, full and
                                        functional laboratory and radio-diagnostic units, comfortable admission facilities as
                                        well as a well-stocked pharmacy. </p>
                                        <h3>Total Healthcare</h3>
                                        
                                        <p>We also provide prompt arrangements for in-house consultation and treatment by
                                        Sub-specialty consultants, as well as reliable referrals to affiliate specialists and
                                        consultants abroad – at clients request. <br/>
                                        You can be assured of all effort being made to provide the best possible standards and
                                        satisfactory medical services at all St. Ives hospitals as we pledge our expertise and
                                        resources to availing you and your family <b>EFFECTIVE TOTAL HEALTHCARE.</b></p>
                                    </div>
                                </div>
                            </div>
        
        
                            <div class="card">
                                <div class="card-header" role="tab" id="headingSix">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSix"
                                        aria-expanded="false" aria-controls="collapseSix">
                                        Core Values<i class="icofont-dotted-down"></i>
                                    </a>
                                </div>
                                <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingSix"
                                    data-parent="#accordionEx">
                                    <div class="card-body">
                                        <img src="../../../../../assets/img/family/core-values.jpg" alt="CoreValues" />
                                        <h3><span>Core Values</span></h3>
                                        <p>
                                            Our existence as an organization and our conducts as professionals are founded on
                                            and guided by the following principles:
                                        </p>
                                        <h3><span>Life</span></h3>
                                        <p>
                                            We believe that the human life is sacrosanct and hence, our expertise, experience,
                                            facilities and resources are dedicated to saving and managing the lives in our care,
                                            the best ways possible for the best quality of life possible.
                                        </p>
                                        <h3><span>Family</span></h3>
                                        <p>
                                            We are enthusiastic about the family as a unit. We believe it is the most important
                                            institution in the society because it is the foundation for the preservation,
                                            propagation and prosperity of the human race. After all, a functional family starts
                                            with a healthy family.
                                        </p>
                                        <h3><span>Professionalism</span></h3>
                                        <p>
                                            Our growing clientele has seen our operations expanding year on year since
                                            inception. This is testament to our diligence and uncompromising commitment to
                                            standards in personnel, facilities and practice.
                                        </p>
                                        <h3><span>Ethics</span></h3>
                                        <p>
                                            We are responsible corporate citizens. Therefore, we are guided by the highest
                                            ethical, moral and legal standards as we go the extra mile in making our healthcare
                                            practice and deliveries effective for our patients.
                                        </p>
                                        <h3><span>Relationship</span></h3>
                                        <p>
                                            We take our clients as family and friends. By entrusting us with the most sensitive
                                            aspects of their lives, we in turn value and treat them with the utmost care and
                                            respect, maintaining constant communication and providing relevant information and
                                            guide on all our efforts in getting them to their desired healthcare destinations.
                                        <h3><span>Excellence</span></h3>
                                        <p>
                                            For us, anything worth doing is worth doing well. From our people to our facilities,
                                            and the procedures and processes we deploy in our operations and service delivery,
                                            we ensure and guarantee nothing less than the best.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <a routerLink="/family-home" class="btn btn-primary"> <span><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.354 10.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"/>
                              </svg></span> Go Back</a>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-12">
                        <div class="faq-image">
                            <img src="assets/img/fertility/stives1080.png" alt="img">
                        </div>
                    </div> -->
                    <div class="col-lg-1"></div>
                </div>
            </div>
        </section>
   

<app-footer></app-footer>