import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-one',
  templateUrl: './blog-one.component.html',
  styleUrls: ['./blog-one.component.scss']
})
export class BlogOneComponent implements OnInit {
  public blogs: any
  public title: string
  public date: Number
  public singleBlogs: any
  
    constructor(public blogService: BlogService, public router: Router, public route: ActivatedRoute) { }
  
    ngOnInit(): void {
      let blogId = this.route.snapshot.paramMap.get('id')
      this.blogService.pullSingleBlog(blogId).valueChanges().subscribe( bRef => {
        this.singleBlogs = bRef
      })
    }
  
    addDataToSomething(blog) {
      this.title = blog.title
      this.date = Date.now();
      // this.blogDetails = blog.details
    }
  
    navigateToBlogDetails(blog: any) {
      console.log(blog)
          let NavigationExtras: NavigationExtras = {
            queryParams:
            {
              title: blog.title,
              image: blog.image,
              author: blog.author,
              details: blog.details,
              date: blog.date,
              refresh: new Date().getTime()
    
            }
          }
    
          this.router.navigate(['blog-1'], NavigationExtras);
      }
    
  }
