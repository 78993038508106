<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/fertility/fertility_logo.jpg" alt="St Ives Fertility logo">
            <img src="assets/img/fertility/fertility_logo.jpg" alt="St Ives Fertility logo">
        </a>
        
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link" href="http://www.stiveshealthcare.com">Landing</a></li>
                <li class="nav-item"><a class="nav-link" href="#fertility-home">Home</a></li>
                <li class="nav-item"><a class="nav-link" href="#about">About Us</a></li>
                <li class="nav-item"><a class="nav-link" href="#fertility-services"> Fertility Services</a></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#portfolio">Fertility Specialist</a></li> -->
                <li class="nav-item"><a class="nav-link" href="#team">Fertility Specialist</a></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#pricing">Pricing</a></li>
                <li class="nav-item"><a class="nav-link" href="#testimonial">Testimonial</a></li> -->
                <li class="nav-item"><a class="nav-link" href="#blog">News & Media</a></li>
                <li class="nav-item"><a class="nav-link" href="http://ivesmedicare.com" target="_blank">HMO</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
            </ul>
        </div>
    </div>
</nav>