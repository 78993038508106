import { Component, OnInit } from '@angular/core';
import { EmailService } from 'src/app/services/email.service';
import { NewsletterService } from 'src/app/services/newsletter.service';

@Component({
  selector: 'app-family-subscribe',
  templateUrl: './family-subscribe.component.html',
  styleUrls: ['./family-subscribe.component.scss']
})
export class FamilySubscribeComponent implements OnInit {
public useremail: string
public msg: any
public newsletterSubscription: boolean = false

  constructor(public emailService: EmailService, public newsletterService: NewsletterService) { }

  ngOnInit(): void {
  }

  newsletterSubscribe() {
    this.newsletterService.addUser(this.useremail).then( user => {
      if (user) {
        console.log(user)
        this.useremail = ""
        this.newsletterSubscription = true
      }
    })
  }
}
