<section class="story-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="story-content">
            <div class="section-title">
                <span>Watch our youtube video to:</span>
                <h2>Get Enlightened about <b>St.Ives</b> Specialist Hospital</h2>
                <a href="https://www.youtube.com/watch?v=f5rhSjkN7YE" class="video-btn popup-youtube"><i class="icofont-ui-play"></i></a>
            </div>
        </div>
    </div>
</section>