import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-family-facilities',
  templateUrl: './family-facilities.component.html',
  styleUrls: ['./family-facilities.component.scss']
})
export class FamilyFacilitiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
