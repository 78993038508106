import { Injectable } from '@angular/core';
import '../../assets/smtp.js'  //path might change
declare let Email : any;

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor() { }

  sendEmail(host, username, password, to, from, subject, msg) {
    
    Email.send({
      Host : `${host}`,
      Username : `${username}`,
      Password : `${password}`,
      To : `${to}`,
      From : `${from}`,
      Subject : `${subject}`,
      Body : `${msg}`
      }).then();
      //  message => {alert('email notification sent successfully ' + message); } );
        
      }
}