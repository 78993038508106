<app-family-navbar></app-family-navbar>

<div id="family-home" class="home-slides owl-carousel owl-theme">
    <div class="main-banner faitem-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1>We provide the <b>best first line of care</b></h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner faitem-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1> In all <b>matters of family health</b></h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner faitem-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1> from <b>pre-natal</b> to <b>neo-natal</b>  </h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="main-banner faitem-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1>From <b>pediatrics</b> to <b>geriatrics</b> </h1>
                        <p> </p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-family-features></app-family-features>

<app-family-about></app-family-about>

<app-family-services></app-family-services>

<app-family-cta></app-family-cta>

<app-family-faq></app-family-faq>

<!-- Newly Exc <app-family-who-we-are></app-family-who-we-are> -->

<!-- <app-skills></app-skills> -->

<app-family-story></app-family-story>


<app-family-facilities></app-family-facilities>

<!-- <app-why-we-different></app-why-we-different> -->

<app-family-funfacts-two></app-family-funfacts-two>

<app-family-team></app-family-team>

<!-- <app-family-portfolio></app-family-portfolio> -->

<!-- <app-pricing></app-pricing> -->

<!-- Newly Exc <app-testimonial></app-testimonial> -->

<!-- <app-call-to-action></app-call-to-action> -->

<!-- <app-faq></app-faq> -->

<!-- <app-partner></app-partner> -->

<!-- <app-family-blog></app-family-blog> -->

<!-- Newly Exc <app-subscribe></app-subscribe> -->

<app-family-blog></app-family-blog>

<app-family-subscribe></app-family-subscribe>

<app-family-contact></app-family-contact>

<app-footer></app-footer>