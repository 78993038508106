
<section id="family-blog" class="blog-area ptb-100">
    <div class="section-title">
        <span>Info Update!</span>
        <h2>Our Latest <b>News</b></h2>
        <p>Get Latest happens in the world on Healthcare</p>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6" *ngFor="let blog of blogs">
                <div class="single-blog-post" routerLink="/blog-details/{{blog.id}}/">
                    <div class="blog-image">
                        <img src="{{blog.image}}" alt="image">
                        <div class="post-tag">
                        <a>{{blog.author}}</a>
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <span class="date">{{blog.date | date:'yyyy-MM-dd'}}</span>
                        <h3><a routerLink="/blog-details">{{blog.title}}</a></h3>
                        <p>{{blog.description}}</p>
                        <a  class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                    </div>
                </div>
            </div>
            
            
            
        </div>
    </div>
</section>