<app-bloodbank-navbar></app-bloodbank-navbar>

<div id="bloodbank-home" class="home-slides owl-carousel owl-theme">
    <div class="main-banner spitem-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1>St Ives <b>Blood Bank</b> is a high tech and state of the art <b>Registered Blood Bank</b></h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner spitem-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1> that collects, seperates and stores <b>whole blood</b> and <b> blood components.</b> </h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner spitem-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1> We deliver in no time with our <b>Bike Medics</b></h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-blodbank-features></app-blodbank-features>

<app-blodbank-about></app-blodbank-about>

<app-bloodbank-contact></app-bloodbank-contact>



<app-footer></app-footer>