import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-family-faq',
  templateUrl: './family-faq.component.html',
  styleUrls: ['./family-faq.component.scss']
})
export class FamilyFaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
