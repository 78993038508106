import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(public afa: AngularFireAuth) { }

  addUser(email) {
    return this.afa.createUserWithEmailAndPassword(email, '2000angels')
    
  }
}
