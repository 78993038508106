<app-navbar></app-navbar>

<div id="fertility-home" class="home-slides owl-carousel owl-theme">
    <div class="main-banner item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1> Is <b>Natural</b> Conception a Challenge?</h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1> Turn to our <b>Reliable Assisted Fertility</b> Solutions </h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1>And the <b>bundle of Joy</b> you desire ...</h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner item-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1>Could soon be in your <b>Loving Arms</b> </h1>
                        <p> </p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-features></app-features>

<app-about></app-about>

<app-services></app-services>

<app-cta></app-cta>

<app-faq></app-faq>

<app-who-we-are></app-who-we-are>

<!-- <app-skills></app-skills> -->

<app-funfacts-two></app-funfacts-two>

<!-- <app-portfolio></app-portfolio> -->

<!-- <app-why-we-different></app-why-we-different> -->

<app-team></app-team>

<app-story></app-story>

<!-- <app-pricing></app-pricing> -->

<app-testimonial></app-testimonial>

<!-- <app-call-to-action></app-call-to-action> -->

<!-- <app-faq></app-faq> -->

<!-- <app-partner></app-partner> -->

<app-blog></app-blog>

<app-subscribe></app-subscribe>

<app-contact></app-contact>
<app-footer></app-footer>