<section id="about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/fertility/stives_half1.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/fertility/stives_half2.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/fertility/about_half1.png" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/fertility/about_half2.png" alt="about">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About St Ives Fertility</span>
                    <h2>Learn about our <b>Fertility</b> Unit</h2>
                    <p>St. Ives IVF/Fertility Unit is a member of the St. Ives Healthcare Organization, a leading
                        private hospital group in Nigeria founded in 1996. </p>
                    <p>In response to the identified needs of a significant number of couples in the society, our
                        IVF/Fertility unit took off in 2007 and it has been an amazing string of unprecedented success
                        stories in assisted conception using first class fertility experts and cutting-edge technology
                        and procedures within a system built on a philosophy of care and total professionalism. </p>
                    <p>Today, St’ Ives IVF/Fertility is the most renowned fertility center in Nigeria. Our success
                        rates in IVF births have been on a consistent increase year on year, and currently stands at an
                        aggregate 60.00% across age groups, which is unarguably among the highest rates in the world. </p>
                    <p>Amongst our uniqueness is the fact that we do a lot more than IVF procedures as we are
                        proficient in all matters of fertility and Assisted Reproductive Technology. We are committed to
                        giving women and couples their hearts’ desire because we believe everyone deserves their own
                        bundle of joy. </p>
                    <!-- <ul>
                        <li>Creative Design</li>
                        <li>Modern Design</li>
                        <li>Retina Ready</li>
                        <li>Awesome Design</li>
                        <li>Responsive Design</li>
                    </ul>
                    <p>We are an experienced and talented team of passionate consultants who live and breathe search
                        engine marketing</p> -->
                </div>
            </div>
        </div>
    </div>
</section>