import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specialist-story',
  templateUrl: './specialist-story.component.html',
  styleUrls: ['./specialist-story.component.scss']
})
export class SpecialistStoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
