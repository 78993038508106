<section class="cta-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-8">
                <div class="cta-content">
                    <h3>Convinced already? <b>Book an Appointment</b> with us</h3>
                    <span>Give us a try, You won't regret it</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 text-right">
                <a href="http://portal.stiveshealthcare.com" class="btn btn-primary">Book an Appointment</a>
            </div>
        </div>
    </div>
</section>