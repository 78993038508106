<section id="fertility-services" class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Services</span>
            <h2>Our <b>Fertility Services</b></h2>
            <p>Services we offer at St.Ives Fertility</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Fertility Check & Assessment<i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                            <div class="card-body">
                                We practice clinical medicine at St. Ives, thus we do not just throw up possible solutions without identifying problems. We provide thorough fertility assessment checks for both men and women as the starting process in our fertility treatment operations.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Ovulation Induction <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                            <div class="card-body">
                                This involves using hormonal medications (usually drugs) to stimulate the ovulation process in those women in whom ovulation does not occur naturally or those with some form of ovulatory dysfunctions such as irregular or absent menses (Anovulation).
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Sperm Aspiration <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                            <div class="card-body">
                                This is a procedure performed on men. Some men have a condition called azoospermia, i.e. an absence of sperm in the ejaculate due to blockage preventing sperm from entering the semen (obstructive azoospermia) or due to a failure of sperm production by the testicles i.e. non-obstructive azoospermia. 
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                In Vitro Fertilization (IVF) <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                            <div class="card-body">
                                In Vitro Fertilization is the process of fertilizing eggs with sperm in the laboratory (instead of inside the fallopian tube) and allowing it grow over two to five days before moving the fertilized embryo into the woman’s uterus through a simple procedure called embryo transfer.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Intra-Uterine Insemination (IUI) <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                            <div class="card-body">
                                IUI is the measured insertion of sperm into a woman’s uterus during ovulation with the ultimate goal of inducing fertilization by increasing the number of sperms that reach the fallopian tubes.
                            </div>
                        </div>
                    </div>
                    
                    <div class="card">
                        <div class="card-header" role="tab" id="headingSix">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Intra-Cytoplasmic Sperm Injection (ICSI) <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingSix" data-parent="#accordionEx">
                            <div class="card-body">
                                ICSI is the procedure where single sperms are injected into the cytoplasm of each egg using a needle. It is a modern technique effective in the treatment of male factor infertility, and is especially applicable for men with Azoospermia or abnormal sperm parameters.
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingSeven">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                Endoscopy Surgery <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseSeven" class="collapse" role="tabpanel" aria-labelledby="headingSeven" data-parent="#accordionEx">
                            <div class="card-body">
                                Endoscopy surgeries are “minimally invasive” surgeries deployed to see inside the human body in order to examine a hollow organ or cavity; and sometimes, these are the appropriate interventions required in diagnosing and treatment of infertility challenges in women.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingEight">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                Laser Assited Hatching (LAH) <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseEight" class="collapse" role="tabpanel" aria-labelledby="headingEight" data-parent="#accordionEx">
                            <div class="card-body">
                                Assisted hatching is technique used to help the embryo hatch from its protective outer shell, the zona pellucida, and promote implantation in the uterine wall after embryo transfer.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingNine">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                Cryopreservation <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseNine" class="collapse" role="tabpanel" aria-labelledby="headingNine" data-parent="#accordionEx">
                            <div class="card-body">
                                We offer cryopreservation services. This include semen bank facilities for the collection and storage of human sperms or eggs, as well as for embryo freezing.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTen">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                Egg Donor <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseTen" class="collapse" role="tabpanel" aria-labelledby="headingTen" data-parent="#accordionEx">
                            <div class="card-body">
                                Sometimes the chances of getting pregnant naturally for some women is no longer a possibility, due to one or a combination of natural or medical conditions. Our fertility operation offers an Egg/Embryo donation program that delivers more than hope to such women but gives them joy to hold and to cherish.
                            </div>
                        </div>
                    </div>
                    <div class="card mb-0">
                        <div class="card-header" role="tab" id="headingEleven">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                Surrogacy <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseEleven" class="collapse" role="tabpanel" aria-labelledby="headingEleven" data-parent="#accordionEx">
                            <div class="card-body">
                                Surrogacy involves an arrangement where a woman (the Surrogate or gestational carrier) becomes pregnant and gives birth to a baby on behalf of another woman (or commissioning couple), who wants to have a child but cannot due to certain reasons or medical conditions that make it impossible or unsafe for the commissioning woman to become pregnant and give birth.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/fertility/stives1080.png" alt="img">
                </div>
            </div>
        </div>
    </div>
</section>