<app-specialist-navbar></app-specialist-navbar>

<div id="specialist-home" class="home-slides owl-carousel owl-theme">
    <div class="main-banner spitem-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1> We are <b>Healthcare Professionals</b> </h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner spitem-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1> Committed to the <b>best quality of life</b> </h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner spitem-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1>Through <b>Preventative and Clinical</b> Medicine.</h1>
                        <p></p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner spitem-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span></span>
                        <h1>Could soon be in your <b>Loving Arms</b> </h1>
                        <p> </p>
                        <!-- <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-specialist-features></app-specialist-features>

<app-specialist-about></app-specialist-about>

<app-specialist-services></app-specialist-services>

<app-specialist-cta></app-specialist-cta>

<app-specialist-faq></app-specialist-faq>

<!-- Newly Exc <app-specialist-who-we-are></app-specialist-who-we-are> -->

<!-- <app-skills></app-skills> -->

<!-- <app-portfolio></app-portfolio> -->

<!-- <app-why-we-different></app-why-we-different> -->

<app-specialist-funfacts-two></app-specialist-funfacts-two>

<!-- <app-specialist-portfolio></app-specialist-portfolio> -->

<app-specialist-team></app-specialist-team>

<app-specialist-story></app-specialist-story>

<!-- <app-pricing></app-pricing> -->

<!-- Newly Exc <app-testimonial></app-testimonial> -->

<!-- <app-call-to-action></app-call-to-action> -->

<!-- <app-faq></app-faq> -->

<!-- <app-partner></app-partner> -->

<app-specialist-blog></app-specialist-blog>

<app-specialist-subscribe></app-specialist-subscribe>

<app-specialist-contact></app-specialist-contact>

<app-footer></app-footer>