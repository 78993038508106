import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specialist-services',
  templateUrl: './specialist-services.component.html',
  styleUrls: ['./specialist-services.component.scss']
})
export class SpecialistServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
