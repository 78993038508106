<section id="family-services" class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Services</span>
            <h2>Our <b>Family Clinic Services</b></h2>
            <p>Services we offer at St.Ives Family Clinic</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne"
                                aria-expanded="false" aria-controls="collapseOne">
                                COMPREHENSIVE HEALTH CHECKS<i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                <p>
                                    Proprietary St. Ives Health Screening Program (SIHSP) is an assortment of essential
                                    medical assessment programs that leave no stones unturned in our commitment to
                                    helping our patients (corporate and individual) go after their life goals at home or
                                    at work with unwavering peace of mind and absolutely no worries.
                                </p>

                                <p>
                                    Our health checks incorporate tests that provide you with an in-depth profile of
                                    your health and wellbeing, comprising of Personal Health Measurements, Iron status,
                                    Diabetes check, Cardiovascular profile and Cardiac plus check, hematology profile
                                    (i.e. full blood profile), thyroid function test, liver function test pancreatic
                                    function test.
                                </p>

                                <h4>These health checks include the following:</h4>
                                <div class="about-content">
                                    <ol>
                                        <li>Pre-Employment Medical Examination</li>
                                        <li>Domestic Staffs Screening</li>
                                        <li>Food Handlers Test</li>
                                        <li>Comprehensive Male Health Checks</li>
                                        <li>Comprehensive Female Health Checks</li>
                                        <li>Pre-Nuptial Health Checks</li>
                                        <li>Executive Health Checks</li>
                                        <li>Fertility</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                                GP SERVICES<i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                St. Ives provides a wide range of general practice medical services, including: advice
                                on health problems, vaccinations, examinations and treatment, prescriptions for
                                medicines, referrals to other health practitioners etc. our family physicians provide
                                the most up-to-date first line of care across various medical disciplines.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree"
                                aria-expanded="false" aria-controls="collapseThree">
                                FULL LABORATORY AND DIAGNOSTIC SERVICES <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                St. Ives offers an international standard 247-365 full laboratory services that serve
                                the test needs of inpatients, outpatients, home-bound patients and emergency departments
                                both within and outside the organization.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour"
                                aria-expanded="false" aria-controls="collapseFour">
                                FULL GYNECOLOGICAL, OBSTETRICS AND PEDIATRICS SERVICES <i
                                    class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                We have a team of expert gynecologists, obstetrics, pediatricians, and other medical
                                specialists with unrivaled knowledge and expertise on all matters of women health.
                                Therefore, our reputation in the areas of pre-natal, antenatal and neo-natal services is
                                based on our reliable first class deliveries in these highly essential specialties.
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive"
                                aria-expanded="false" aria-controls="collapseFive">
                                ADMISSION FACILITIES AND SERVICES <i class="icofont-dotted-down"></i>
                            </a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive"
                            data-parent="#accordionEx">
                            <div class="card-body">
                                With well-equipped comfortable wards Our admission facilities are second to none in
                                terms of comfort and convenience, and are guaranteed to help you make the most in terms
                                recovery to good health and vitality.
                            </div>
                        </div>
                    </div>

                </div>

                <!-- <a href="specialist-services" class="btn btn-primary">Know more</a> -->
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="">
                    <img src="../../../../../assets/img/family/allstaffs.jpg" alt="All Staffs">
                </div>
            </div>
        </div>
    </div>
</section>