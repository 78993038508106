<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/specialist/specialist_logo.png" alt="St Ives logo">
            <img src="assets/img/specialist/specialist_logo.png" alt="St Ives logo">
        </a>
        
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link" routerLink="/"> Landing</a></li>
                <li class="nav-item"><a class="nav-link" href="#bloodbank-home"> Home </a></li>
                <li class="nav-item"><a class="nav-link" href="#bloodbank-about">About Bloodbank</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                <!-- <li class="nav-item"><a class="nav-link" href="http://portal.stiveshealthcare.com">Book an Appointment</a></li> -->

            </ul>
        </div>
    </div>
</nav>