<section id="testimonial" class="testimonial-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span>What people say about us</span>
            <h2>Our <b>Testimonial</b></h2>
            <p>Don't hold out on us, you can be the next to give testimony</p>
        </div>
        <div class="testimonial-slides owl-carousel owl-theme">
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Where can I start my true-life story? I am here to encourage fellow women not to lose hope.
                        </p>
                        <p> I’m based in the U.S and I have been married for over 23years. I have done 4 failed IVF
                            procedures, 2 in U.S.A., 1 in India and 1 in a hospital here in Nigeria. After a while, I
                            decided to start over again. At this point I was confused as regards to the clinic/centre to
                            use. A family friend of ours directed us to St. Ives Hospital and I booked an appointment to
                            see the MD.
                        </p>
                            
                        <p> I know I’m of age, so I went straight by using egg donor and today I have done my
                            confirmatory scan and I’m carrying two babies. I thank all St. Ives IVF team especially
                            Nurse Mary for her perseverance, the embryologist, Nurse Inameti, Sumbo and Gloria. They
                            have been wonderful.
                        </p>
                    </div>
                    <div class="client-info">
                        <h3> </h3>
                        <span>23-year quest around the world ends with St.Ives Solution </span>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Considering my age (58 years), I can’t stop wondering what really happened and how it was
                            done right at St. Ives Fertility Center. I have been trying since when I was younger: I did
                            all manners of treatment including traditional and spiritual ways, all to no avail. </p>

                        <p> Then I was introduced to St. Ives Hospital by a family friend in Sagamu to retry IVF, I felt
                            reluctant after 4 failed IVF procedures at some previous places. But when I met Nurse Mary,
                            she gave me hope and encouraged me. </p>

                        <p> In 2017 November, I did the IVF with St. Ives Hospital, I must say to the Glory of God, I’m
                            progressing without problems and my baby is bouncing.
                        </p>
                    </div>
                    <div class="client-info">
                        <h3> Mrs. A. S`</h3>
                        <span>Finally Expectant at 58 years of age</span>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>I don’t know where to start the story of my life without a child, but simply put: it was
                            distressing. I Mrs. N.K has been married for 12years, trying all sorts of treatment, all to
                            no avail. In October 2017, I did my first IVF with St. Ives Hospital during their promo.
                            After 2weeks of embryo transfer and a 3-day transfer, my pregnancy test came out positive. I
                            then did a scan and a single fetus was confirmed: a boy.
                        </p>

                        <p> All thanks to God Almighty, St. Ives IVF team and other staffs, especially Nurse Mary for
                            her encouragement and hope, Dr Obodo, Sumbo and others.
                        </p>
                    </div>
                    <div class="client-info">
                        <h3>Mrs. N. K</h3>
                        <span>Finally finds a positive after 12 years of negative</span>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>My joy knows no bound; I Mrs. M.O is a living witness of what God has been doing through St.
                            Ives Fertility Center. After waiting for the fruit of the womb for over sixteen (16) years,
                            going from one church to another, one hospital to another, a friend introduced me to St.
                            Ives where she did hers and had a set of twins.
                        </p>

                        <p> Through God’s infinite mercy and with the help and care of the IVF team at St. Ives
                            hospital, I did IVF once and I’m carrying a set of triplets. That’s my first attempt. All
                            thanks to Nurse Mary, Dr Obodo, the embryologist, Nurse Sumbo, Gloria and other staff.
                        </p>
                    </div>
                    <div class="client-info">
                        <h3>Mrs. M. O</h3>
                        <span>Lands a set of triplet at first attempt</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>