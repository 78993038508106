<footer class="footer-area">
    <div class="container" style="padding-bottom: 20px;">
        <div class="col-lg-12 col-md-6">
            <ul>
                <li><a href="https://web.facebook.com/St.IvesSpecialistHospital" target="_blank"><i class="icofont-facebook"></i></a></li>
                <li><a href="https://twitter.com/stives_hospital" target="_blank"><i class="icofont-twitter"></i></a></li>
                <li><a href="https://www.instagram.com/st.ivesspecialist" target="_blank"><i class="icofont-instagram"></i></a></li>
            </ul>
        </div>
        <div class="row align-items-center">
        </div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-9 col-md-8s">
                <p><i class="icofont-copyright"></i>2020 St.Ives Specialist Hospital - <a routerLink="/privacy">Privacy Policy</a></p>
            </div>
            <div class="col-lg-3 col-md-4">
                <p> Designed by <a href="info@lesgilles.com">Les Gilles IT Solutions</a></p>
            </div>   
        </div>
    </div>
</footer>

<div class="go-top"><i class="icofont-swoosh-up"></i></div>