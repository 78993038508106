<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/family/stives_family.png" alt="St Ives Family logo">
            <img src="assets/img/family/stives_family.png" alt="St Ives Family logo">
        </a>
        
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link" routerLink="/">Landing</a></li>
                <li class="nav-item"><a class="nav-link" href="#family-home">Home</a></li>
                <li class="nav-item"><a class="nav-link" href="#family-about">About Us</a></li>
                <li class="nav-item"><a class="nav-link" href="#family-services"> Family Services</a></li>
                <li class="nav-item"><a class="nav-link" href="#family-facilities"> Family Facilities</a></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#portfolio">Fertility Specialist</a></li> -->
                <li class="nav-item"><a class="nav-link" href="#family-team">Family Team</a></li>
                <li class="nav-item"><a class="nav-link" href="#family-blog"> News & Media </a></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#pricing">Pricing</a></li>
                <li class="nav-item"><a class="nav-link" href="#testimonial">Testimonial</a></li> -->
                <!-- <li class="nav-item"><a class="nav-link" href="#specialist-blog">Blog</a></li>
                <li class="nav-item"><a class="nav-link" href="#specialist-blog">News & Media</a></li> -->
                <li class="nav-item"><a class="nav-link" href="http://ivesmedicare.com" target="_blank">HMO</a></li>
                <li class="nav-item"><a class="nav-link" href="#family-contact">Contact</a></li>
            </ul>
        </div>
    </div>
</nav>