import { Component, OnInit } from '@angular/core';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public useremail: string
  public msg: any
  public subject: string
  public number: string
  public name: string
  public message: string


  constructor(public emailService: EmailService) { }

  ngOnInit(): void {
  }

  sendMail() {
    this.msg = `A user just sent amessage to our contact service with the following details: <br/> ${this.name} <br/> ${this.useremail} <br/> ${this.number} <br/> ${this.subject} <br/> ${this.message}`
   
     this.emailService.sendEmail(
      'smtp.elasticemail.com',
      'info@stiveshealthcare.com',
      'E4F2D1230B710B0C1E9C6C9EFB0D66E8A1EC',
      `info@stiveshealthcare.com`,
      'info@stiveshealthcare.com',
      'Website Contact',
      this.msg)
      this.useremail=""
      this.message=""
      this.name=""
      this.number=""
      this.subject=""
  }
}

