import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specialist-features',
  templateUrl: './specialist-features.component.html',
  styleUrls: ['./specialist-features.component.scss']
})
export class SpecialistFeaturesComponent implements OnInit {

  constructor() { }

    ngOnInit() {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Choose St Ives Specialist',
            title: 'Why Choose Us',
            paragraphText: 'Read where we rank and what other patients says about us'
            
        }
    ]

    singleFeatures: singleFeaturesContent[] = [
        {
            icon: 'icofont-pie-chart',
            title: 'Accurate Health Checks',
            paragraphText: 'Best and authentic health care facilities for accurate check up.'
        },
        {
            icon: 'icofont-edit',
            title: 'Digital Medical Diagnostics',
            paragraphText: 'Accurate identification of ailments with digital equipments.'
            
        },
        {
            icon: 'icofont-upload-alt',
            title: 'Regular Social Media Presence',
            paragraphText: 'Up to date social media services and youtube videos.'
        },
        
    ]

}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}

class singleFeaturesContent {
    icon : string;
    title : string;
    paragraphText : string;
}