<section id="services" class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Sections of work</span>
            <h2>Our <b> Departments</b></h2>
            <p></p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/specialist/sp-diagnostics.jpg" alt="MedicalDiagnostics" />
                        <div class="icon">
                            <i class="icofont-headphone-alt-3"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Medical Diagnostics</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/specialist/sp-s-healthcheck.jpg" alt="ComprehensiveHealthCheck">
                        <div class="icon">
                            <i class="icofont-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Comprehensive Health Checks</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/specialist/sp-surgical-service.jpg" alt="SurgicalServices">
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Surgical Services</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/specialist/sp-s-internal-med.jpg" alt="InternalMedicine" />
                        <div class="icon">
                            <i class="icofont-headphone-alt-3"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Internal Medicine </h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/specialist/sp-ob-gyn.jpg" alt="OB-GYN" />
                        <div class="icon">
                            <i class="icofont-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>OB-GYN</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/specialist/sp-pediatrics.jpg" alt="Pediatrics" />
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Pediatrics</h3>
                        <p></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/specialist/sp-healthcare.jpg" alt="PublicHealth" />
                        <div class="icon">
                            <i class="icofont-headphone-alt-3"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Public Health</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/specialist/sp-critical-care.jpg" alt="CriticalCare" />
                        <div class="icon">
                            <i class="icofont-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Critical Care Medicine</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/specialist/sp-emergency.jpg" alt="Emergency" />
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Emergency</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>