<section id="team" class="team-area ptb-100 bg-image">
    <!-- <div class="container">
        <div class="section-title">
            <span>Meet Our Expert</span>
            <h2>Medical <b>Team</b></h2>
            <p>Our Medical team, the brains behind our operations</p>
        </div>
    
    <div class="">
        
    </div>    
        <div class="team-slides owl-carousel owl-theme">
            <div class="team-box">
                <img src="assets/img/fertility/okewale.png" alt="Dr-Wale-Okewale">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Dr. Babatunde Wale Okewale</h3>
                        <span class="post">CEO, St.Ives Organization</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/fertility/DrObodo.png" alt="Dr-Kingsley">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Dr. Kingsley Obodo</h3>
                        <span class="post">Medical Director, St Ives Specialist Ikeja</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/fertility/DrOsahon.png" alt="Dr-Osahon">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Dr. O. Ede-Edokpolor</h3>
                        <span class="post">Medical Director St.Ives Specialist Ikoyi</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/fertility/Layade.png" alt="Layade-Adeniyi">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Layade Adeniyi</h3>
                        <span class="post">Clinical Embryologist St.Ives IVF</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/fertility/DrFolashade.png" alt="Dr-F-Adegbesan">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Dr. F. Adegbesan</h3>
                        <span class="post">Fertility Specialist St.Ives IVF</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/fertility/Uzoma.png" alt="Ozioma-Chukwudi">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Ozioma Chukwudi</h3>
                        <span class="post">Clinical Embryologist St.Ives IVF</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img src="assets/img/fertility/Damilola.png" alt="Damilola-Onifade">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Damilola Onifade</h3>
                        <span class="post">Clinical Embryologist St.Ives IVF</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="section-title">
        <span>Meet Our Expert</span>
        <h2>Medical <b>Team</b></h2>
        <p>Our Medical team, the brains behind our operations</p>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/okewale.jpg">
                    </div>
                    <h3 class="title">Dr. Babatunde Wale Okewale MD, MFFP(UK), FRCOG(UK)</h3>
                    <span class="post">CEO, St. Ives Organization</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../.././../../assets/img/team/DrAlozie.png">
                    </div>
                    <h3 class="title">Dr. Alozie Ndubuka MD</h3>
                        <span class="post">Medical Director, St. Ives Family Clinic, Akowonjo</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/DrAnigbodu.png">
                    </div>
                    <h3 class="title">Dr. Eunice Anigbodu MD</h3>
                        <span class="post">Family Health Specialist, St. Ives Healthcare Organization</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../.././../../../assets/img/team/DrKingsley.png">
                    </div>
                    <h3 class="title">Dr. Kingsley Obodo MD, FMCOG</h3>
                        <span class="post">Medical Director, St. Ives Specialist Ikeja</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
     
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/Dr.ozioma.png">
                    </div>
                    <h3 class="title">Ozioma Chukwudi Bsc. Microbiology, Msc. Public health</h3>
                    <span class="post">Head Embryologist/Andrologist, St. Ives IVF</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/team08.png">
                    </div>
                    <h3 class="title">Onifade Damilola Emmanuel Bsc. Microbiology, Msc Genetics</h3>
                    <span class="post">Clinical Andrologist/ Embryologist, St. Ives IVF</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/Dr.fato.png">
                    </div>
                    <h3 class="title">Fatoyinbo Korede Ibrahim</h3>
                    <span class="post">Radiography, St. Ives Hospital</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/team10.png">
                    </div>
                    <h3 class="title">Dr. Akinola Olufunmilola Omowunmi MD,FMC(PAED), MBCHB</h3>
                    <span class="post">Paediatrician, St. Ives Hospital</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/DR.Juliet.png">
                    </div>
                    <h3 class="title">Dr. Juliet Nwachukwu MD</h3>
                    <span class="post">Gynaecologist/Medical Officer (GP), St. Ives Hospital</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../assets/img/team/Dr.Chile.png">
                    </div>
                    <h3 class="title">Dr. Chile Adaeze MD</h3>
                    <span class="post">General Practitioner, St. Ives Hospital</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../assets/img/team/Dr.Uwah.png">
                    </div>
                    <h3 class="title">Dr. Uwah Utibe MD</h3>
                    <span class="post">General Practitioner, St. Ives Hospital</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/Dr.Ogbonaiya.png">
                    </div>
                    <h3 class="title">Dr. Ogbonaiya Ndubuisi MD</h3>
                    <span class="post">General Practitioner, St. Ives Hospital</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/Obinna.png">
                    </div>
                    <h3 class="title">Obinna Obuka</h3>
                    <span class="post">General Practitioner, St. Ives Hospital</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/Dr.Ahmed.png">
                    </div>
                    <h3 class="title">Dr. Ahmed Aminat Olayinka MD</h3>
                    <span class="post">Gynaecologist, St. Ives Hospital</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/Dr.Aboloje.png">
                    </div>
                    <h3 class="title">Dr. Aboloje Collins Emuobonuvie MD </h3>
                    <span class="post">Resident Doctor, St. Ives Hospital Abeokuta</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="our-team">
                    <div class="pic">
                        <img src="../../../../../assets/img/team/Dr.Ibrahim.png">
                    </div>
                    <h3 class="title">Dr. Ibrahim Adewole Moshood MD</h3>
                    <span class="post">Gynaecologist, St. Ives Hospital</span>
                    <!-- <ul class="social">
                        <li><a href="#" class="icofont-facebook"></a></li>
                        <li><a href="#" class="icofont-twitter"></a></li>
                        <li><a href="#" class="icofont-google-plus"></a></li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</section>