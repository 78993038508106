<section id="family-services" class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Sections of work</span>
            <h2>Our <b> Departments</b></h2>
            <p></p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/family/family.png" alt="FAMILYHEALTH" />
                        <div class="icon">
                            <i class="icofont-users"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Family Health</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/family/obstetrics.png" alt="Obstetrics&Gynaecology">
                        <div class="icon">
                            <i class="icofont-doctor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Obstetrics & Gynaecology</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/specialist/sp-surgical-service.jpg" alt="GeneralSurgery" />
                        <div class="icon">
                            <i class="icofont-surgeon"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>General Surgery</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/family/laboratory.png" alt="Laboratory" />
                        <div class="icon">
                            <i class="icofont-laboratory"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Laboratory & Diagnostics</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/family/emmergency.png" alt="Emergency" />
                        <div class="icon">
                            <i class="icofont-ambulance-cross"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Emergency</h3>
                        <p></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/family/children-health.png" alt="children-health" />
                        <div class="icon">
                            <i class="icofont-kid"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>
                            Children Health</h3>
                        <p></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/fertility/cardial-clinic.jpg" alt="Cardiac&Sreening">
                        <div class="icon">
                            <i class="icofont-heart-beat"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Cardiac & Screening</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-8 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../../assets/img/fertility/cardial-clinic.jpg" alt="Cardiac&Sreening">
                        <div class="icon">
                            <i class="icofont-heart-beat"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Cardiac & Screening</h3>
                        <p></p>
                    </div>
                </div>
            </div> -->
            
        </div>
    </div>
</section>