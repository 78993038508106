<section id="services" class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Sections of work</span>
            <h2>Our <b> Departments</b></h2>
            <p></p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="../../../../assets/img/fertility/stives-center.jpg" alt="StIves-Fertility-Staffs">
                        <div class="icon">
                            <i class="icofont-headphone-alt-3"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Primary Health Care</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/fertility/gynaecological-clinic.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Gynaecological Clinic</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/fertility/diagnostic-center.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Diagnosis With Precise</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/fertility/cardial-clinic.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-headphone-alt-3"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Cardiac Clinic</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/fertility/general-surgery.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>General Surgery</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/fertility/rehabilitation-studio.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Rehabilitation Studio</h3>
                        <p></p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>