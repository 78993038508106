import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specialist-about',
  templateUrl: './specialist-about.component.html',
  styleUrls: ['./specialist-about.component.scss']
})
export class SpecialistAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
