import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specialist-who-we-are',
  templateUrl: './specialist-who-we-are.component.html',
  styleUrls: ['./specialist-who-we-are.component.scss']
})
export class SpecialistWhoWeAreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
