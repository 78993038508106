import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-family-features',
  templateUrl: './family-features.component.html',
  styleUrls: ['./family-features.component.scss']
})
export class FamilyFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Choose St Ives Family Clinic',
            title: 'Why Choose Us',
            paragraphText: 'We promise to always give the best care to you & your family'
            
        }
    ]

    singleFeatures: singleFeaturesContent[] = [
        {
            icon: 'icofont-hospital',
            title: 'New Clinic Open Periodically',
            paragraphText: 'Recently opened clinic in Abeokuta, Ogun State.',
        },
        {
            icon: 'icofont-icu',
            title: 'Best Facilities',
            paragraphText: 'We give you the best care needed with our state of the arts facilities.',
            
        },
        {
            icon: 'icofont-video-alt',
            title: 'Regular Social Media Presence',
            paragraphText: 'Up to date social media services and youtube videos.',
        },
        
    ]

}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}

class singleFeaturesContent {
    icon : string;
    title : string;
    paragraphText : string;
}