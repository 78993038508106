<section class="page-title-area item-bg3">
    <div class="container">
        <a style="float: left;" class="btn back-btn" routerLink="/family-home"> <span><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.354 10.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"/>
          </svg></span> Go Back </a>
        <h2>Blog Details</h2>
    </div>
</section>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12" *ngFor= "let singleBlog of singleBlogs">
                <div class="blog-details">
                    <div class="post-image">
                        <img src="{{singleBlog.image}}" alt="image">
                    </div>
                    <h3><a routerLink="/blog-details">{{singleBlog.title}}</a></h3>
                    <div class="blog-meta">
                        <ul>
                            <!-- <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li> -->
                            <li><i class="icofont-clock-time"></i><span class="date">{{singleBlog.date | date:'yyyy-MM-dd'}}</span></li>
                            <!-- <li><i class="icofont-comment"></i> <a href="#">6 Comments</a></li> -->
                            <!-- <li><i class="icofont-ui-folder"></i> <a href="#">Event</a></li> -->
                        </ul>
                    </div>
                    <b><a>{{singleBlog.author}}</a></b>
                    <!-- <blockquote class="blockquote">
                        <p>There are many variations of passages of Lorem the Ipsum available but the that as that majority have is suffered alteration.</p>
                    </blockquote> -->
                    <p>{{singleBlog.details}}</p>
                </div>
            </div>
        </div>
        <a style="color: white; background: #855fa8;" class="btn back-btn" routerLink="/family-home"> <span><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.354 10.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"/>
          </svg></span> Go Back </a>
    </div>
</section>


<app-footer></app-footer>