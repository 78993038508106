import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
public blogs: any
public title: string
public date: Number

  constructor(public blogService: BlogService, public router: Router) { }

  ngOnInit(): void {
    this.blogService.pullBlogs().valueChanges().subscribe( blogRef => {
      console.log(blogRef)
      this.blogs = blogRef

    })
  }

  addDataToSomething(blog) {
    this.title = blog.title
    this.date = Date.now();
    // this.blogDetails = blog.details
  }

  navigateToBlogDetails(blog: any) {
    console.log(blog)
        let NavigationExtras: NavigationExtras = {
          queryParams:
          {
            title: blog.title,
            image: blog.image,
            author: blog.author,
            details: blog.details,
            date: blog.date,
            refresh: new Date().getTime()
  
          }
        }
  
        this.router.navigate(['blog-details'], NavigationExtras);
    }
  
}
