import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Choose St Ives Fertility',
            title: 'Why Choose Us',
            paragraphText: 'Read where we rank and what other patients says about us'
            
        }
    ]

    singleFeatures: singleFeaturesContent[] = [
        {
            icon: 'icofont-pie-chart',
            title: '2000+ Clinical Pregnancy',
            paragraphText: 'With good professional expertise your child delivery is at safe hands.'
        },
        {
            icon: 'icofont-edit',
            title: '60.00% Success Rate',
            paragraphText: 'View our yearly statistics of how successful our IVF treatment option fair.'
            
        },
        {
            icon: 'icofont-upload-alt',
            title: 'Over 2 Decades of Experience',
            paragraphText: 'We have been and we will still be doing what we do best.'
        },
        
    ]

}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}

class singleFeaturesContent {
    icon : string;
    title : string;
    paragraphText : string;
}